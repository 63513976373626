#block-views-vdrops-news-view-block-1 {
  background-color: #eeeeee;
  padding: 60px 50px 80px 50px;

  @include respond-to(mobile) {
    padding: 60px 70px 80px 70px;
  }

  @include respond-to(pad) {
    padding: 60px 58px 80px 58px;
  }

  @include respond-to(laptop) {
    padding: 60px 90px 80px 90px;
  }

  .block__title {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    font-size: 26px;
    color: #27499d;
    text-align: center;

    @include respond-to(pad) {
      margin-bottom: 40px;
    }
  }

  .view-content {
    width: 100%;
    margin: 0 auto;

    @include respond-to(laptop) {
      max-width: 1100px;
    }

    @include respond-to(pc) {
      max-width: 1420px;
    }

    .item-list {
      >ul {
        padding: 0;
        margin: -12px 0;

        @include respond-to(s) {
          display: flex;
          margin: 0 -10px;
        }

        @include respond-to(laptop) {
          margin: 0px -50px;
        }

        @include respond-to(pc) {
          margin: 0px -70px;
        }

        >li {
          list-style: none;
          padding: 15px 0;

          @include respond-to(s) {
            flex-basis: 33.33%;
            max-width: 33.33%;
            padding: 0 10px;
          }

          @include respond-to(laptop) {
            padding: 0px 50px;
          }

          @include respond-to(pc) {
            padding: 0px 70px;
          }

          .field-name-field-news-image {
            padding-bottom: 15px;

            a {
              display: block;

              img {
                display: block;
                width: 100%;
              }
            }

          }

          .content-warp {
            .field-name-title {
              padding-bottom: 12px;

              .field-item {
                h2 {
                  margin: 0;
                  margin-top: 8px;
                  line-height: 28px;

                  a {
                    text-decoration: none;
                    color: #27499d;
                    font-size: 24px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                  }
                }
              }
            }

            .tax-warp {
              display: flex;
              margin-top: 0px;
              font-size: 16px;
              color: #27499d;

              .field-name-field-news-tax {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
