#block-views-vdrops-benefit-block-1,
#block-views-vdrops-what-is-block-1,
#block-views-vdrops-our-water-block-1 {
  h2.block__title {
    font-size: 24px;
    font-weight: bold;
    color: #27499d;
    display: flex;
    margin: 32px 0 16px 0;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      margin-top: 8px;
      margin-right: 8px;
      border-style: solid;
      border-width: 9px 0 9px 10px;
      border-color: transparent transparent transparent #84dbff;
    }
  }

  .view-content {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;

      @include respond-to(pad) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include respond-to(laptop) {
        flex-direction: column;
      }

      li.views-row {
        padding: 16px 10px;
        margin: 0 -10px;
        width: 100%;

        @include respond-to(pad) {
          flex-basis: 50%;
          max-width: 50%;
          padding: 16px 32px 16px 0;
          margin: 0;
        }

        @include respond-to(laptop) {
          flex-basis: 100%;
          max-width: 100%;
        }

        .contextual-links {
          display: none;
        }

        .node {
          display: flex;

          .field-type-image {
            max-width: 40%;
            padding: 0;

            a {
              height: auto !important;

              img {
                display: block;
              }
            }
          }

          .field-name-title {
            padding-left: 20px;

            @include respond-to(laptop) {
              padding-left: 13px;
            }

            h2 {
              margin: 0;
              line-height: 1.22;
              font-size: 18px;
              font-weight: bold;
            }

            a {
              color: #27499d;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
