#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px 50px;
  @include respond-to(mobile) {
    padding: 20px 76px 50px;
  }
  @include respond-to(s) {
    padding: 20px 58px 79px;
  }
  h2.block__title {
    color: #27499d;
    font-size: 26px;
    font-weight: bold;
    // width: 300px;
    // height: 50px;
    border-radius: 15px;
    border: 2px solid #84dbff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    margin-bottom: 40px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    @include zen-respond-to(pad) {
      max-width: 360px !important;
    }
  }
  .view-views-frontpage-featured-block{
    max-width: 1420px;
    width: 100%;
  }

  .view-content {
    .item-list {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        // align-items: flex-start;
        align-items: center;
        flex-direction: column;
        @include respond-to(pad) {
          flex-direction: row;
          align-items: flex-start;
        }
        li {
          list-style: none;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          flex-basis: 33.3333%;
          position: relative;
          margin-bottom: 40px;
          @include respond-to(pad) {
            margin-bottom: 60px;
          }
          .views-field-title,
          .views-field-title-field {
            color: #84dbff;
            font-size: 30px;
            font-weight: bold;
            line-height: 28px;
            text-align: center;
            @include respond-to(laptop) {
              font-size: 26px;
              line-height: 32px;
            }
          }
          img {
            display: block;
            max-width: 100%;
          }
          .views-field-field-wart-image {
            margin-bottom: 8px;
            @include respond-to(pad) {
              margin-bottom: 14px;
            }
          }
          .views-field-field-active-image {
            display: none;
            position: absolute;
            top: 0;
            margin-bottom: 8px;
            @include respond-to(pad) {
              margin-bottom: 14px;
            }
          }
          
          .views-field-field-feature-content {
            display: block;
            margin-top: 30px;
            text-align: center;
            font-size: 22px;
            @include respond-to(pad) {
              display: none
            }
            p{
              margin: 0;
            }
          }
          .views-field-field-active-image,
          .views-field-field-wart-image {
            width: 57px;
            height: 80px;
          }
        }
        .views-row-1 {
          .views-field-title {}
        }
        .views-row-2 {
          &::before,
          &::after {
            content: '';
            display: none;
            width: 50%;
            height: 1px;
            background-color: #84dbff;
            position: absolute;
            top: 45%;
            @include respond-to(pad) {
              display: block
            }
          }
          &::before {
            @include respond-to(pad) {
              left: -65px;
            }
            @include respond-to(laptop) {
              left: -150px;
            }
          }
          &::after {
            @include respond-to(pad) {
              right: -65px;
            }
            @include respond-to(laptop) {
              right: -150px;
            }
          }
          .views-field-title {}
        }
        .views-row-3 {
          .views-field-title {}
        }
      }
      .drop-active-content-warp {
        display: none;
        width: 100%;
        text-align: center;
        // padding: 0 50px;
        @include respond-to(mobile) {
          // padding: 0 70px;
        }
        @include respond-to(s) {
          // padding: 0 58px;
        }
        @include respond-to(pad) {
          display: block;
        }
        .style {
          font-size: 22px;
          font-weight: normal;
          color: #000000;
          // height: 200px;
          text-align: center;
          line-height: normal;
          @include respond-to(laptop) {
            font-size: 26px;
          }
        }
      }
    }
  }
}
