#block-views-vdrops-product-view-block-3{
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    // @include zen-respond-to(pad) {
    //     padding: 0 58px;
    // }
    @include zen-respond-to(laptop) {
        padding: 0 10px;
    }
    .view-content{
        border-bottom: 1px solid #84dbff;
        .views-row-first{
            max-width: 1120px;
            width: 100%;
            list-style: none;
        }
        .product-slide-block{
            display: flex;
            flex-wrap: wrap;
            > div{
                flex-basis: 100%;
                max-width: 100%;
                @include zen-respond-to(xs) {
                    flex-basis: 50%;
                    max-width: 50%;
                }
            }
            .info{
                .field-name-title{
                    margin-bottom: 8px;
                    @include zen-respond-to(xs) {
                        margin-bottom: 18px;
                    }
                    h2{
                        margin: 0;
                        font-size: 30px;
                        line-height: 40px;
                        color: #27499d;
                        text-align: left;
                        @include zen-respond-to(xs) {
                            font-size: 36px;
                            line-height: 42px;
                        }
                    }
                }
               
                .field-name-field-product-feature{
                    font-size: 18px;
                    color: #323237;
                    margin-bottom: 18px;
                    @include zen-respond-to(xs) {
                        font-size: 24px;
                        line-height: 36px;
                        margin-bottom: 30px;

                    }
                }
                .field-name-field-product-narrative,
                .field-name-field-product-price{
                    text-align: left;
                    font-size: 18px;
                }
                .field-name-field-product-narrative{
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    &::before{
                        content: "入數：";
                    }
                }
                .field-name-field-product-price{
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    &::before{
                        content: "售價：";
                    }
                    .field-label{
                        display: none;
                    }
                }
                .field-name-field-product-shopping{
                    margin-bottom: 32px;
                    a{
                        text-decoration: none;
                        padding: 12px 34px;
                        background-color: #84dbff;
                        font-size: 18px;
                        color: #fff;
                        border-radius: 18px;
                        display: block;
                        width: 140px;
                    }
                }
            }

            .slide-wrapper{
                img{
                    // width: 100%;
                }
                .slick__slider{
                    padding: 0;
                    
                }
                .slick__arrow{
                    > button{
                        opacity: 0.6;
                        top: 50%;
                        @include zen-respond-to(xs) {
                            width: 24px;
                            height: 48px;
                            background-size: 24px 48px;
                        }
                        &:hover{
                            opacity: 1;
                        }
                    }
                    .slick-prev{
                        left: 0;
                        background-image: url(../images/icon/previous.png);
                    }
                    .slick-next{
                        right: 0;
                        background-image: url(../images/icon/next.png);
                    }
                }
            }
            
        }
    }
}

.i18n-en{
    #block-views-vdrops-product-view-block-3{
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
    // @include zen-respond-to(pad) {
    //     padding: 0 58px;
    // }
    @include zen-respond-to(laptop) {
        padding: 0 10px;
    }
    .view-content{
        border-bottom: 1px solid #84dbff;
        .views-row-first{
            max-width: 1120px;
            width: 100%;
            list-style: none;
        }
        .product-slide-block{
            display: flex;
            flex-wrap: wrap;
            > div{
                flex-basis: 100%;
                max-width: 100%;
                @include zen-respond-to(xs) {
                    flex-basis: 50%;
                    max-width: 50%;
                }
            }
            .info{
                .field-name-title{
                    margin-bottom: 8px;
                    @include zen-respond-to(xs) {
                        margin-bottom: 18px;
                    }
                    h2{
                        margin: 0;
                        font-size: 30px;
                        line-height: 40px;
                        color: #27499d;
                        text-align: left;
                        @include zen-respond-to(xs) {
                            font-size: 36px;
                            line-height: 42px;
                        }
                    }
                }
               
                .field-name-field-product-feature{
                    font-size: 18px;
                    color: #323237;
                    margin-bottom: 18px;
                    @include zen-respond-to(xs) {
                        font-size: 24px;
                        line-height: 36px;
                        margin-bottom: 30px;

                    }
                }
                .field-name-field-product-narrative,
                .field-name-field-product-price{
                    text-align: left;
                    font-size: 18px;
                }
                .field-name-field-product-narrative{
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    &::before{
                        content: "Quantity：";
                    }
                }
                .field-name-field-product-price{
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    &::before{
                        content: "Price：";
                    }
                    .field-label{
                        display: none;
                    }
                }
                .field-name-field-product-shopping{
                    margin-bottom: 32px;
                    a{
                        text-decoration: none;
                        padding: 12px 34px;
                        background-color: #84dbff;
                        font-size: 18px;
                        color: #fff;
                        border-radius: 18px;
                        display: block;
                        width: 140px;
                    }
                }
            }

            .slide-wrapper{
                img{
                    // width: 100%;
                }
                .slick__slider{
                    padding: 0;
                    
                }
                .slick__arrow{
                    > button{
                        opacity: 0.6;
                        top: 50%;
                        @include zen-respond-to(xs) {
                            width: 24px;
                            height: 48px;
                            background-size: 24px 48px;
                        }
                        &:hover{
                            opacity: 1;
                        }
                    }
                    .slick-prev{
                        left: 0;
                        background-image: url(../images/icon/previous.png);
                    }
                    .slick-next{
                        right: 0;
                        background-image: url(../images/icon/next.png);
                    }
                }
            }
            
        }
    }
}
}


#block-views-vdrops-product-view-block-2{
    h2.block__title {
        font-size: 24px;
        font-weight: bold;
        color: #27499d;
        display: flex;
        margin: 32px 0 16px 0;
    
        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          margin-top: 8px;
          margin-right: 8px;
          border-style: solid;
          border-width: 9px 0 9px 10px;
          border-color: transparent transparent transparent #84dbff;
        }
    }
    .view-content{
        > div{
            > ul{
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                li{
                    list-style: none;
                    flex-basis: 100%;
                    max-width: 100%;
                    @include zen-respond-to(pad) {
                        flex-basis: 50%;
                        max-width: 50%;
                    }
                    @include zen-respond-to(laptop) {
                        flex-basis: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .product-rm{
        display: flex;
        margin: 0 -10px;
        > div{
            padding: 0 10px;
        }
        .field-name-field-product-image{
            flex-basis: 120px;
            max-width: 120px;
            @include zen-respond-to(mobile) {
                flex-basis: 180px;
                max-width: 180px;
            }
            @include zen-respond-to(pad) {
                flex-basis: 31%;
                max-width: 31%;
            }
            @include zen-respond-to(laptop) {
                flex-basis: 43%;
                max-width: 43%;
            }
            img{
                // width: 100%;
            }
        }
        .content-warp{
            .field-name-title{
                margin-bottom: 5px;
                h2{
                    margin: 0;
                    a{
                        font-size: 18px;
                        color: #27499d;
                        line-height: 22px;
                        text-decoration: none;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
            }
            .field-name-field-product-narrative,
            .field-name-field-product-price{
                text-align: left;
                font-size: 14px;
            }
           
        }
    }
}
.field-name-addtoany{
    margin-bottom: 10px !important;
    @include zen-respond-to(xs) {
        margin-bottom: 25px !important;
    }
    
    a{
       > .a2a_svg{
           width: 24px !important;
           height: 24px !important;
           border-radius: 0px !important;
       } 
    }
    .a2a_button_email{
        > .a2a_svg{
            background-color: #F5952E !important;
        }
    }
}