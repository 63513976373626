header {
  a.header__logo {
    margin: 0;
    padding: 0;
  }
  ul.menu {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      a {
        text-decoration: none;
        color: #27499d;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

.header-warp {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 8px;
  height: 64px;
  box-sizing: border-box;
  background-color: #fff;
  @include respond-to(laptop) {
    height: 106px;
    padding: 20px 10px;
  }
  a.header__logo {
    float: none;
    margin: 0;
    padding: 0;
    display: block;
    img {
      display: block;
      width: 160px;
      height: 48px;
      max-width: 100%;
      @include respond-to(laptop) {
        width: 220px;
        height: 67px;
      }
    }
  }
  .region-header {
    // 這個是pc時的選單
    display: none;
    @include respond-to(pc) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
    }
    ul.menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      li {
        padding: 0 0 0 23.5px;
        a.active {
          color: #27499d;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: '';
            display: block;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            margin-right: 7px;
            background-image: url(/sites/all/themes/vdrops/images/menu-active.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  .mhb-bar {
    flex-basis: 50%;
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
    @include respond-to(pc) {
      display: none;
    }
    .warp {
      width: 32px;
      height: 22px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      width: 100%;
      height: 3px;
      background-color: #27499d;
      display: block;
      border-radius: 2px;
      position: relative;
      &::before,
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: #27499d;
        display: block;
        border-radius: 5px;
        position: absolute;
        transform: rotate(0deg);
        transition: all .2s;
      }
      &::before {
        top: -10px;
      }
      &::after {
        top: 10px;
      }
    }
    .mhb-open {
      span {
        background-color: transparent;
        &::before,
        &::after {
          top: 0;
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

header {
  width: 100%;
  .rwd-menu {
    display: none;
    position: fixed; // top: -100%;
    width: 100%;
    height: 100%;
    z-index: 99;
    transition: all .5s;
    @include respond-to(laptop) {
      width: 360px;
      right: 0;
    }
    ul.menu {
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: 80%;
      overflow: auto;
      li {
        flex-basis: 50%;
        max-width: 50%;
        text-align: center;
        padding: 35px;
        @include respond-to(mobile) {
          flex-basis: 100%;
          max-width: 100%;
          padding: 24px 0;
        }
        a.active {
          color: #000;
          @include respond-to(laptop) {
            color: #27499d;
            display: flex;
            justify-content: center;
            align-items: center;
            &::before {
              content: '';
              display: block;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              margin-right: 7px;
              background-image: url(/sites/all/themes/vdrops/images/menu-active.png);
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
  .menu-toggle {}
}

header {
  .region-header {
    #block-block-5 {
      p {
        margin: 0;
        margin-left: 47px;
      }
    }
  }
  #translateLink {
    margin: 14px 0;
    text-decoration: none;
    background-color: #27499d;
    color: #fff;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond-to(pc) {
      margin: 0;
    }
  }
  .sl-fade {
    animation: go-sl .2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-direction: normal; // display: none;
  }
  @keyframes go-sl {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

#block-lang-dropdown-language-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
  &::before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(../images/icon/globe-americas@3x.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 5px;
  }
  select {
    outline: none;
    border: none;
    background-color: #eeeeee;
    width: 108px !important;
    height: 32px;
    position: relative;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    padding-left: 2px;
  }
}
