// Dependencies.
// Allow layout-center's margin/padding to override layout-3col.
@import 'layouts/layout-3col/layout-3col';
// Layout Center
//
// All other layouts should be nested inside this centered layout to ensure that
// content does not get too wide on very large screens.
//
// Applying a `.layout-*` class to the same HTML element as `.layout-center`
// would cause that element's grid to mis-align with the rest of the grid on the
// page, but this component will automatically detect the other `.layout-*`
// class and automatically use the `.layout-center--shared-grid` variant
// instead.
//
// .layout-center--shared-grid - If `.layout-center` is applied to the same div
//   as another .layout-* class, then this variant should be used to prevent the
//   shared layout's grid from mis-aligning. Note: Each time a new .layout-*
//   component is created, this component will need to be modified.
//
// Markup: layout-center.twig
//
// Weight: -1
//
// Style guide: layouts.layout-center
.hide_h1 {
  h1 {
    display: none;
  }
}

.layout-center,
%layout-center {
  margin: 0 auto;
  max-width: 100%;
  @include zen-apply-gutter-padding();
  &--shared-grid {
    padding-left: 0;
    padding-right: 0;
  }
  @include respond-to(pad) {
    // max-width: 650px;
  }
  @include respond-to(laptop) {
    max-width: 1100px;
  }
}

//
// Fugly selectors.
//
.layout-center {
  // Ensure the .layout-center--shared-grid variant is automatically used by
  // adding all other .layout-* classes here.
  &.layout-3col {
    @extend %layout-center--shared-grid;
  }
}

body.front {
  .layout-center,
  %layout-center {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
