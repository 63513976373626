.breadcrumb {
  padding-left: 10px;
  margin: 0 auto;
  margin-bottom: 47px;
  @include respond-to(laptop) {
    max-width: 1100px;
  }
  span,
  a {
    text-decoration: none;
    color: #7d7d7d;
    font-size: 14px;
  }
  .delimiter {
    font-size: 9px;
  }
}
