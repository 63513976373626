.page-news {
  // h1 {
  //   margin-bottom: 24px;
  //   color: #27499d;
  //   font-size: 36px;
  //   text-align: center;

  //   @include respond-to(laptop) {
  //     font-size: 48px;
  //     margin-bottom: 48px;

  //   }
  // }
  // 上方區塊
  #block-views-vdrops-news-view-block-2 {
    width: 100%;
    margin: 0 auto;
    @include respond-to(laptop) {
      max-width: 1100px;
    }
    > h2{
      margin-bottom: 24px;
      color: #27499d;
      font-size: 36px;
      text-align: center;
  
      @include respond-to(laptop) {
        font-size: 48px;
        margin-bottom: 48px;
  
      }
    }
    .view-display-id-block_2 {
      // &:after {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: 1px;
      //   background-color: #84dbff;
        
      // }

      // 切換的箭頭
      .slick__arrow {
        .slick-prev {
          background-image: url("../images/icon/arrow-left.png");
          // left: 10px;
          top: 50%;
          transform: translateY(-50%);

          @include zen-respond-to(laptop) {
            left: -45px;
            top: 0;
          }

          &::before {
            color: unset;
            content: unset;
          }
        }

        .slick-next {
          background-image: url("../images/icon/arrow-right.png");
          // right: 10px;
          top: 50%;
          transform: translateY(-50%);
          @include zen-respond-to(laptop) {
            right: -45px;
            top: 0;
          }

          &::before {
            color: unset;
            content: unset;
          }
        }

        .slick-prev,
        .slick-next {
          width: 17px;
          height: 37px;
          border-radius: 0;
          background-size: 17px 37px;
        }
      }

      // 區塊中間內容
      .slick__slider {
        margin-top: 10px;

        .slick-list {
          .slick-track {
            .slick__slide {
              display: flex;
              justify-content: center;
              outline: 0;
              padding: 0 24px;
              @include zen-respond-to(mobile) {
                padding: 0 32px;
              }
              @include zen-respond-to(pad) {
                padding: 0 50px;
              }
              @include zen-respond-to(laptop) {
                background-color: #eeeeee;
                padding: 0;
              }

              .slide__content {
                .view-mode-new_3 {
                  .news-content-wrapper-3 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 50px;

                    @include zen-respond-to(mobile) {
                      margin: 0;
                      padding: 0 60px;
                    }

                    @include zen-respond-to(pad) {
                      flex-direction: row;
                      padding: 16px 0;
                    }

                    .image-cover-3 {

                      @include zen-respond-to(pad) {
                        flex-basis: 50%;
                        // max-width: 316px;
                        padding-left: 10px;
                      }

                      @include zen-respond-to(laptop) {
                        flex-basis: 50%;
                        max-width: 502px;
                        padding: 16px;
                      }

                      .field-name-field-news-image {

                        a {
                          img {
                            width: 100%;
                            display: block;
                          }
                        }
                      }
                    }

                    .content-wrapper {
                      @include zen-respond-to(pad) {
                        flex-basis: 50%;
                        padding-left: 21px;
                      }

                      @include zen-respond-to(laptop) {
                        padding: 16px;
                        padding-bottom: 0;
                      }

                      .title {
                        padding: 15px 0 11px 0;

                        @include zen-respond-to(pad) {
                          padding: 0 0 11px 0;
                        }

                        @include zen-respond-to(laptop) {
                          padding: 0 0 18px 0;
                        }

                        h2 {
                          margin: 0;
                          line-height: 28px;
                          font-weight: normal;
                          font-weight: bold;

                          @include zen-respond-to(pad) {
                            padding: 0;
                          }

                          a {
                            text-decoration: none;
                            color: #27499d;
                            font-size: 24px;

                            @include zen-respond-to(laptop) {
                              font-size: 36px;
                              line-height: 40px;
                            }
                          }
                        }
                      }

                      .date-taxonomy {
                        display: flex;

                        .field-name-post-date {
                          font-size: 16px;
                          color: #27499d;
                          font-weight: bold;
                        }

                        .field-name-field-news-tax {
                          font-size: 16px;
                          color: #27499d;
                          font-weight: bold;
                          padding-left: 5px;
                        }
                      }

                      .body-link {
                        display: none;

                        @include zen-respond-to(pad) {
                          display: block;
                          font-size: 16px;
                          padding-top: 17px;
                          // margin-right: -10px;

                          @include zen-respond-to(laptop) {
                            font-size: 18px;
                            padding-top: 31px;
                          }

                          .field-name-node-link {
                            display: none;

                            @include zen-respond-to(laptop) {
                              display: block;
                              padding-top: 29px;

                              a {
                                text-decoration: none;
                                color: #27499d;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // 下方列表樣式
  .view-vdrops-news-view {
    display: flex;
    flex-direction: column;
    // 分類
    .view-filters {
      padding: 0 16px;
      border-top: 1px solid #84dbff;
      .views-exposed-form {
        margin: 32px 0 16px 0;

        @include zen-respond-to(laptop) {
          margin: 48px 0;
        }

        .views-exposed-widgets {
          display: flex;
          justify-content: center;

          .views-widget-filter-field_news_tax_target_id {
            padding: 0 10px 0 0;
            position: relative;

            &::after {
              @include zen-respond-to(laptop) {
                top: 22px;
              }

              content: '';
              position: absolute;
              top: 10px;
              right: 20px;
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 10px 7.5px 0 7.5px;
              border-color: #000000 transparent transparent transparent;
            }

            select {

              border: 0px;
              width: 140px;
              height: 28px;
              padding: 3px 0 3px 7px;
              font-size: 14px;
              background-color: #eeeeee;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;

              @include zen-respond-to(laptop) {
                padding: 7px 0 7px 14px;
                width: 220px;
                height: 50px;
                font-size: 18px;
              }
            }
          }

          .views-widget-sort-order {
            display: none;
          }

          .views-submit-button {
            display: none;
          }

          .views-widget-sort-by {
            padding: 0 0 0 10px;

            .form-item-sort-by {
              position: relative;

              &::after {
                @include zen-respond-to(laptop) {
                  top: 22px;
                  right: 20px;
                }
                content: '';
                position: absolute;
                top: 10px;
                right: 10px;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 7.5px 0 7.5px;
                border-color: #000000 transparent transparent transparent;
              }

              label {
                display: none;
              }

              select {
                border: 0px;
                width: 140px;
                height: 28px;
                padding: 3px 0 3px 7px;
                font-size: 14px;
                background-color: #eeeeee;
                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
                @include zen-respond-to(laptop) {
                  width: 220px;
                  height: 50px;
                  font-size: 18px;
                  padding: 7px 0 7px 14px;
                }
              }
            }

          }
        }
      }
    }

    // 列表項目
    .view-content {
      .item-list {
        ul.news_view_list {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          flex-direction: column;

          @include zen-respond-to(pad) {
            flex-direction: row;
            flex-wrap: wrap;
          }

          @include zen-respond-to(laptop) {
           margin: -24px -50px;
          }

          li {
            padding: 16px 10px;
            margin: 0 -10px;

            &:hover {
              background-color: #eeeeee;
            }

            @include zen-respond-to(pad) {
              max-width: 50%;
              flex-basis: 50%;
              margin: 0;
              padding: 16px 10px;

              &:hover {
                background-color: unset;
              }
            }

            @include zen-respond-to(laptop) {
              max-width: 33.333%;
              flex-basis: 33.333%;
              // padding-left: 0;
              padding: 24px 50px;

              &:hover {
                background-color: unset;
              }
            }

            .view-mode-new_1 {
              .news-content-wrapper {
                display: flex;

                @include zen-respond-to(laptop) {
                  flex-direction: column;

                }

                .image-cover {
                  .field-name-field-news-image {
                    @include zen-respond-to(mobile) {
                      width: 160px;
                    }

                    @include zen-respond-to(pad) {
                      width: 124px;
                    }

                    @include zen-respond-to(laptop) {
                      width: 100%;
                    }

                    width: 100px;

                    .field-items {
                      .field-item {
                        a {
                          img {
                            width: 100%;
                            display: block;
                          }
                        }
                      }
                    }
                  }
                }

                .content-wrapper {
                  .title {
                    padding-left: 22px;

                    @include zen-respond-to(laptop) {
                      padding-left: 20px;
                    }

                    @include zen-respond-to(laptop) {
                      padding: 16px 0 12px 0;
                      // margin-right: -16px;
                    }

                    h2 {
                      margin: 0;
                      line-height: 18px;
                      font-weight: normal;

                      a {
                        text-decoration: none;
                        color: #27499d;
                        font-size: 18px;

                        @include zen-respond-to(laptop) {
                          font-size: 24px;
                          line-height: 30px;

                        }
                      }
                    }
                  }

                  .date-taxonomy {
                    padding-left: 22px;
                    display: flex;

                    @include zen-respond-to(laptop) {
                      padding-left: 0;
                    }

                    .field-name-post-date {
                      font-size: 14px;
                      color: #27499d;

                      @include zen-respond-to(laptop) {
                        font-size: 16px;
                      }
                    }

                    .field-name-field-news-tax {
                      font-size: 14px;
                      color: #27499d;
                      padding-left: 5px;

                      @include zen-respond-to(pad) {
                        margin-right: -16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
