.node-type-contact{
    .field-name-body .field-item .info-warp h2.title{
        margin-top: 32px;
        @include zen-respond-to(pad){
            margin-top: 0px;
        }
    }
    .field-name-body{
        &::after{
            margin: 31px 0 32px;
        }
    }
    #webform-client-form-50{
        >div{
            @include zen-respond-to(pad){
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                flex-wrap: wrap;
                max-width: 556px;
                margin: 0 auto;
            }
            @include zen-respond-to(laptop){
                max-width: 620px;
            }
        }
        >div::before{
            text-align: center;
            margin-bottom: 32px;
        }
        .form-item{
            margin: 0;
            margin-bottom: 32px;
            @include zen-respond-to(mobile){
                margin-left:90px;
                margin-right:90px; 
            }
            @include zen-respond-to(pad){
                margin-left:0px;
                margin-right:0px; 
            }
        }
        .webform-component--name,.webform-component--tel{
            margin-top: 32px;
        }
        .form-actions{
            @include zen-respond-to(pad){
            justify-content: flex-end;
            margin-top: -40px;
            .clear{
                margin-right: 0;
            }
        }
        }
        .webform-component--name,.webform-component--email{
            @include zen-respond-to(pad){
                padding-right: 10px;
            }
        }
        .webform-component--tel,.webform-component--qa-select{
            @include zen-respond-to(pad){
                padding-left: 10px;
            }
        }
        .webform-component--name,.webform-component--tel,.webform-component--email,.webform-component--qa-select{
            @include zen-respond-to(pad){
            flex-basis: 50%;
            max-width: 50%;
            }
        }
        .form-actions,.webform-component--privacy{
            @include zen-respond-to(pad){
            flex-basis: 50%;
            }
        }
        .webform-component--header,.webform-component--message{
            flex-basis: 100%;
            max-width: 100%;
        }
        .webform-component-fieldset{
            font-size: 18px;
            line-height: 1.67;
            color: #323237;
            text-align: center;
            @include zen-respond-to(pad){
                text-align:start;
            }
        }

        .form-item-submitted-privacy-1{
            display: flex;
            @include zen-respond-to(mobile){
                margin-left:0px;
                margin-right:0px; 
            }
            #edit-submitted-privacy-1{
                width: 18px;
                height: 18px;
                border-radius: 3px;
                border: 1px solid #7d7d7d;
                background: #ffffff;
                align-self: center;
                margin-right: 9px;
            }
            .option{
                font-size: 16px;
            }
        }
    }
}