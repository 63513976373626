.page-benefit,
.page-what-is,
.page-our-water{
    h1{
        margin-bottom: 34px;
        color: #27499d;
        font-size: 36px;
        text-align: center;
        @include respond-to(769px) {
            font-size: 48px;
            margin-bottom: 48px;

        }
    }
    .view-header{
        p{
            margin: 0;
            margin-bottom: 36px;
            font-size: 18px;
            color: #323237;
            text-align: center;
            line-height: normal;
            line-height: 36px;
            @include respond-to(769px) {
                font-size: 24px;
                margin-bottom: 70px;
                line-height: 48px;

            }
        }
    }
    .view-content{
        .item-list{
            > ul{
                margin: 0;
                padding: 0;
                > li{
                    position: relative;
                    list-style: none;
                    margin-bottom: 24px;
                    .views-field-title,
                    .views-field-title-field{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                        text-align: center;
                        padding: 0 31px;
                        a{
                            font-size: 24px;
                            text-decoration: none;
                            color: #fff;
                            text-shadow: 3px 2px 6px #000000;
                            display: block;
                            line-height: normal;
                            @include respond-to(769px) {
                                font-size: 36px;
                            }
                            img{
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }   
    }
}

.node-type-benefit,
.node-type-what-is,
.node-type-our-water{
    .node{
        .field-name-title{
            h2{
                margin: 0;
                margin-bottom: 20px;
                font-size: 36px;
                color: #27499d;
            }
        }
    }
}