#block-views-vdrops-slideshow-block {
  .slick-dots {
    display: none !important;
    margin: 0;
    margin-bottom: 8px;
    bottom: 0;
    @include respond-to(pc) {
      display: block !important;
    }
  }
  button {
    padding: 0;
    &::before {
      color: #ffffff;
      font-size: 16px;
      opacity: 1;
      transition: all .5s;
    }
  }
  .slick-active {
    button {
      &::before {
        color: #27499d;
        opacity: 1;
      }
    }
  }
}
