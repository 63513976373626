.region-bottom {
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
  }
  ul.menu {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
}

.region-bottom {
  .info-warp {
    @include respond-to(laptop) {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-width: 1100px;
      margin: 0 auto;
      margin-top: 50px;
      @include respond-to(pc) {
        max-width: 1440px;
      }
    }
    .info-block {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 40px 10px;
      @include respond-to(laptop) {
        padding: 0;
        flex-basis: 30%;
        align-items: flex-start;
        max-width: 30%;
      }
      .view-id-vdrops_footer_block{
        ul{
          padding: 0;
          margin: 0;
          li{
            list-style: none;

          }
        }
      }
      p,
      a {
        color: #27499d;
        font-size: 18px;
        font-weight: normal;
      }
      p {
        margin: 6px 0;
      }
      >div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include respond-to(laptop) {
          align-items: flex-start;
        }
      }
      .media-element-container {
        margin-bottom: 31px;
        >div {
          .content {
            width: 108px;
            height: 97px;
            @include respond-to(laptop) {
              width: 140px;
              height: 125px;
            }
            img {
              display: block;
              max-width: 100%;
            }
          }
        }
      }
      .community-warp {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 29px;
        @include respond-to(laptop) {
          margin-top: 13px;
        }
        a {
          display: block;
          width: 36px;
          height: 36px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          margin: 0 10px;
          @include respond-to(laptop) {
            margin: 0 8px 0 0;
          }
        }
        .line {
          background-image: url(/sites/all/themes/vdrops/images/icon/sns-line@3x.png);
        }
        .fb {
          background-image: url(/sites/all/themes/vdrops/images/icon/sns-facebook@3x.png);
        }
      }
    }
    .menu-block {
      display: none;
      @include respond-to(laptop) {
        display: block;
        flex-basis: 70%;
        max-width: 70%;
      }
      #block-menu-menu-menu-sitemap-menu {
        a {
          color: #180001;
        }
        >ul.menu {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          li.expanded {
            width: 160px;
            margin: 0 20px;
            @include respond-to(pc) {
              margin: 0 40px;
            }
            >a {
              font-size: 18px;
              font-weight: bold;
              text-align: left;
              color: #180001;
              &::after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                background-color: #000000;
              }
            }
            ul.menu {
              margin-top: 40px;
              li {
                padding: 5px 0;
                a {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  .copy {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background-color: #27499d;
    padding: 10px 18px;
    text-align: center;
    margin-top: 40px;
    @include respond-to(laptop) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.region-footer {
  //首頁聯絡我們區塊
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url(/sites/all/themes/vdrops/images/contact-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @include respond-to(laptop) {
    height: 363px;
  }
  @include respond-to(pc) {
    height: 544px;
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    z-index: 1;
  }
  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    @include respond-to(pad) {
      padding: 0;
      margin: 0 auto; // max-width: 650px;
    }
    @include respond-to(laptop) {
      // max-width: 800px;
    }
    h2 {
      display: none;
    }
    .bean-contact-us-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .field-name-title-field .field-item {
      color: #fff;
      margin: 0;
      padding: 0;
      line-height: 1;
      font-size: 24px;
      font-weight: bold;
      position: relative;
      z-index: 5;
      text-align: center;
      line-height: normal;
      @include respond-to(laptop) {
        font-size: 30px;
      }
    }
    .field-type-link-field {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        width: 160px;
        height: 40px;
        margin-top: 40px;
        border: 3px solid #84dbff;
        background-color: transparent;
        border-radius: 15px;
        position: relative;
        z-index: 5;
        @include respond-to(laptop) {
          font-size: 24px;
          width: 300px;
          height: 50px;
          margin-top: 80px;
        }
      }
    }
  }
}

body:not(.front) {
  .region-footer {
    @include respond-to(pc) {
      height: 240px;
    }
    >div {
      @include respond-to(pc) {
        .bean-contact-us-block {
          max-width: 100%;
          flex-direction: row;
        }
      }
      .field-type-link-field {
        a {
          @include respond-to(pc) {
            margin-top: 0;
            margin-left: 180px;
          }
        }
      }
    }
  }
}

body.front {
  .region-footer {
    margin-top: 0;
  }
}
