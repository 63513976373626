
  // 側邊欄在about-us.scss裡面
  .view-id-vdrops_qa_view {
    // 列表頁
    margin-top: 34px;
    @include respond-to(pad) {
      margin-top: 49px;
    }
    @include respond-to(laptop) {
      margin-top: 0;
    }
    .item-list {
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          border-bottom: 1px solid #eee;
          margin-bottom: 33px;
          padding-bottom: 40px;
          >div {
            .field-name-title {
              h2 {
                color: #27499d;
                font-size: 36px;
                font-weight: bold;
                margin: 0;
              }
            }
            .field-name-body {
              font-size: 18px;
              font-weight: normal;
              color: #323237;
              margin: 23px 0 26px;
            }
            .field-name-node-link {
              a {
                text-decoration: none;
                font-size: 16px;
                font-weight: bold;
                color: #27499d;
              }
            }
          }
        }
        li:last-child {
          border: none;
        }
      }
    }
  }

.page-qa{
  .pager{
    margin-bottom: 15px !important;
  }
}

.node-type-qa,
.node-type-news {
  .field-name-title {
    h2 {
      color: #27499d;
      font-size: 36px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 24px;
    }
  }
  .tax-warp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .field-name-post-date {
      margin-right: 10px;
    }
    .field-name-post-date,
    .field-name-field-news-tax {
      //分類
      font-size: 16px;
      font-weight: bold;
      color: #27499d;
    }
  }
  .field-name-addtoany {
    margin-bottom: 26px;
  } //延伸閱讀
  aside.layout-3col__right-sidebar {
    h2.block__title {
      margin: 0;
      font-size: 24px;
      font-weight: bold;
      color: #27499d;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 10px;
        border-color: transparent transparent transparent #84daff;
        margin-right: 10px;
      }
    }
    .block-views {
      ul {
        padding: 0;
        margin: 0;
        li {
          margin: 10px 0;
          list-style: none;
          .node-qa{
            display: flex;
            .field-type-image {
              max-width: 100%;
              padding: 0 ;
            }
            .field-name-title {
              padding-left: 20px;
              flex-basis: 59.9999%;
              @include respond-to(laptop) {
                  padding-left: 13px;
              }
              h2 {
                margin: 0;
                line-height: 1.22;
                font-size: 18px;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
              }
  
              a {
                color: #27499d;
                text-decoration: none;
              }
            }
          }
        }
      }
      .view-id-vdrops_qa_view {
        //常見問題的延伸閱讀
        .item-list {
          h2 {
            margin: 0;
            a {
              text-decoration: none;
              font-size: 18px;
              font-weight: bold;
              color: #27499d;
            }
          }
        }
      }
      .view-id-vdrops_news_view {
        //最新消息的延伸閱讀
        .item-list {
          ul.news_view_list {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @include respond-to(pad) {
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-start;
              margin: 0 -15px;
            }
            @include respond-to(laptop) {}
            li {
              padding: 15px 0;
              @include respond-to(pad) {
                flex-basis: 50%;
                max-width: 50%;
                margin: 0;
                padding: 15px;
              }
              @include respond-to(laptop) {
                flex-basis: 100%;
                max-width: 100%;
              }
            }
          }
          .node {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: row;
            .field-type-image {
              flex-basis: 35%;
              max-width: 35%;
              img {
                display: block;
                max-width: 100%;
              }
            }
            .content-warp {
              flex-basis: 65%;
              max-width: 65%;
              padding-left: 10px;
              @include respond-to(pad) {
                padding-left: 20px;
              }
              .field-name-title {
                h2 {
                  margin: 0;
                  a {
                    display: block;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: bold;
                    color: #27499d;
                    line-height: 1;
                  }
                }
              }
            }
            .tax-warp {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-top: 10px;
              .field-name-post-date {
                margin-right: 10px;
              }
              .field-name-post-date,
              .field-name-field-news-tax {
                //分類
                font-size: 14px;
                font-weight: bold;
                color: #27499d;
              }
            }
          }
        }
      }
    } // .item-list {
    //   h2 {
    //     margin: 0;
    //     a {
    //       text-decoration: none;
    //       font-size: 18px;
    //       font-weight: bold;
    //       color: #27499d;
    //     }
    //   }
    // }
  }
}

.node-type-news {
  .field-name-title {
    h2 {
      
      margin-bottom: 0px;
    }
  }
}
