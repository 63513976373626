#colorbox {
  #cboxContent {
    #cboxPrevious,
    #cboxNext,
    #cboxClose {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      outline: none;
      border: none;
      transition: all .3s;
    }
    #cboxPrevious,
    #cboxNext {
      width: 26px;
      height: 48px;
      &:hover {
        width: 26px;
        height: 48px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        outline: none;
        border: none;
      }
    }
    #cboxPrevious {
      top: 50%;
      left: -120px;
      background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-back.png);
      &:hover {
        background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-back-active.png);
      }
    }
    #cboxNext {
      top: 50%;
      right: -120px;
      background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-next.png);
      &:hover {
        background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-next-active.png);
      }
    }
    #cboxClose {
      top: 0;
      right: -50px;
      background-image: url(/sites/all/themes/vdrops/images/icon/close@3x.png);
      width: 36px;
      height: 36px;
    }
  }
}
