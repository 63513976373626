.node-type-contact {
  .field-name-body {
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: #84dbff;
      margin: 47px 0 32px;
    }
    .field-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      @include respond-to(pad) {
        align-items: flex-start;
        flex-direction: row;
      }
      >p {
        margin: 0;
        width: 160px;
        height: 144px;
        @include respond-to(pad) {
          width: 124px;
          height: 111px;
          margin-right: 68px;
        }
        @include respond-to(laptop) {
          width: 220px;
          height: 197px;
          margin-right: 100px;
        }
      }
      img {
        display: block;
        max-width: 100%;
      }
      .info-warp {
        text-align: center;
        @include respond-to(pad) {
          text-align: left;
        }
        h2.title {
          font-size: 36px;
          font-weight: bold;
          color: #27499d;
          margin: 0;
        }
        p {
          font-size: 18px;
          font-weight: normal;
          color: #323237;
        }
        a {
          text-decoration: none;
          color: #323237;
        }
        .community-warp {
          >div {
            padding: 10px 0;
            a,
            p {
              margin: 0;
              padding: 0;
              display: block;
            }
            a {
              padding: 10px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              @include respond-to(pad) {
                justify-content: flex-start;
                align-items: flex-start;
              }
            }
            a::after {
              content: '';
              display: block;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
            }
          }
          .line-warp {
            a::after {
              content: '';
              background-image: url(/sites/all/themes/vdrops/images/icon/find-us-line@3x.png);
              width: 90px;
              height: 32px;
            }
          }
          .fb-warp {
            a::after {
              content: '';
              background-image: url(/sites/all/themes/vdrops/images/icon/find-us-fb@3x.png);
              width: 138px;
              height: 32px;
            }
          }
        }
      }
    }
  }
  form.webform-client-form {
    >div {
      .form-required,
      .grippie {
        display: none;
      }
      &::before {
        content: '連絡表單';
        display: block;
        color: #27499d;
        font-size: 24px;
        font-weight: bold;
      }
      .webform-progressbar {
        display: none;
      }
      fieldset {
        border: none;
        padding: 0;
        margin: 0;
        legend {
          display: none;
        }
        .fieldset-legend {
          font-size: 18px;
          font-weight: normal;
          color: #323237;
        }
      }
      .form-item:not(.webform-component-select) {
        position: relative;
        span {
          position: absolute;
          top: -11px;
          left: 10px;
          background-color: #fff;
          font-size: 14px;
        }
        label {
          display: none;
        }
        input,
        textarea {
          border: 1px solid #7d7d7d;
          border-radius: 5px;
          outline: none;
          padding: 15px 20px 10px;
          &:focus {
            border: 1px solid #27499d;
          }
        }
        input:focus~span {
          color: #27499d;
        }
        textarea:focus~span {
          color: #27499d;
        }
        [for=edit-submitted-privacy] {
          display: none;
        }
      }
      .webform-component--tel {
        span {
          left: 10px !important;
          @include respond-to(pad) {
            left: 20px !important;
          }
        }
      }
      .webform-component-select {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 22px;
          right: 20px;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7.5px 0 7.5px;
          border-color: #000000 transparent transparent transparent;
        }
        label {
          display: none;
        }
        select {
          background-color: #fff;
          border: 1px solid #7d7d7d;
          border-radius: 5px;
          outline: none;
          width: 100%;
          height: 52px;
          padding: 0 20px;
          appearance: none;
        }
      }
      .form-checkboxes {
        .form-type-checkbox {
          input {
            border: none;
            outline: none;
            padding: 0;
          }
          label {
            display: inline;
            position: static;
            top: 0;
            left: 0;
            background-color: transparent;
            a {
              text-decoration: none;
              color: #27499d;
            }
          }
        }
      }
    }
    .form-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        width: 120px;
        height: 40px;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border: none;
        outline: none;
        display: block;
        margin: 0 10px;
      }
      .form-submit {
        background-color: #27499d;
      }
      .clear {
        background-color: #c2c2c2;
      }
    }
  }
}

//英文
.i18n-en.node-type-contact{
  
  form.webform-client-form {
    >div {
      &::before {
        content: 'Forms';
        display: block;
        color: #27499d;
        font-size: 24px;
        font-weight: bold;
      }
      
    }
    .form-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        width: 173px;
        height: 40px;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        border: none;
        outline: none;
        display: block;
        margin: 0 10px;
      }
      .form-submit {
        background-color: #27499d;
      }
      .clear {
        background-color: #c2c2c2;
      }
    }
    
  }
}
//日文
.i18n-ja.node-type-contact{
  form.webform-client-form {
    >div {
      &::before {
        content: 'お問い合わせフォーム';
        display: block;
        color: #27499d;
        font-size: 24px;
        font-weight: bold;
      }
      
    }
    
  }
}