.node-type-about-us,
.node-type-test-report,
.page-qa,
.page-taxonomy-term {
  aside.layout-3col__left-sidebar {
    #block-menu-menu-about-us-menu,
    #block-menu-menu-qa-menu {
      margin-bottom: 30px;
      @include respond-to(pc) {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0px;
      }
      .block__title {
        display: none;
      }
      ul.menu {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        @include respond-to(laptop) {
          flex-direction: column;
          justify-content: flex-start;
        }
        li {
          @include respond-to(laptop) {
            width: 100%;
            border-right: 1px solid #eee;
          }
          list-style: none;
          a {
            font-size: 18px;
            font-weight: bold;
            padding: 16px;
            text-decoration: none;
            color: #323237;
            display: block;
          }
          a.active {
            background-color: #eee;
          }
        }
      }
    }
  }
  .node {
    .field-name-title {
      h2 {
        margin: 0;
        text-align: left;
        color: #27499d;
        font-size: 36px;
        font-weight: bold;
      }
    }
  }
}

.node-type-about-us,
.node-type-test-report {
  .node {
    border-bottom: 1px solid #84dbff;
  }
  .pager-warp {
    margin-top: 15px;
    position: relative;
    height: 26px;
    >div {
      position: absolute;
      top: 0;
      margin: 0;
      padding: 0;
    }
    .pager-back {
      left: 0;
      a {
        &::before {
          content: '';
          display: block;
          background-image: url(/sites/all/themes/vdrops/images/icon/page-back.png);
          margin-right: 15px;
        }
      }
    }
    .pager-next {
      padding: 0;
      right: 0;
      a {
        &::after {
          content: '';
          display: block;
          background-image: url(/sites/all/themes/vdrops/images/icon/page-next.png);
          margin-left: 15px;
        }
      }
    }
    a {
      color: #27499d;
      text-decoration: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &::after,&::before {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.entity_pager {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  padding-top: 16px !important;
  border-top: 1px solid #84dbff;
  .entity_pager_count {
    display: none;
  }
  li {
    .inactive {
      display: none;
    }
    a {
      font-size: 18px;
      color: #27499d;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }
  .entity_pager_next {
    a {
      &::after {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border: 3px solid #27499d;
        border-left: 0;
        border-bottom: 0;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-left: 16px;
      }
    }
  }
  .entity_pager_prev {
    a {
      &::before {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border: 3px solid #27499d;
        border-right: 0;
        border-top: 0;
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-right: 16px;
      }
    }
  }
}

.node-type-test-report {
  .paragraphs-items {
    .field-name-field-content-introduce {
      .field-items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .field-item {
          .field-name-field-paragraph-image {
            a,
            img {
              display: block;
            }
            .field-items {
              .field-item {
                display: none;
              }
              .field-item:first-child {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                background-color: #000;
                img {
                  opacity: 1;
                  transition: all .3s;
                }
                &:hover {
                  img {
                    opacity: .5;
                  }
                  &::after {
                    content: '放大顯示 + ';
                    display: block;
                    position: absolute;
                    text-align: center;
                    color: #ffffff;
                    top: 45%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
