@charset "UTF-8";
@font-face {
  font-family: 'Microsoft JhengHei', 'Noto Sans', 'Noto Sans TC', 'Roboto', sans-serif;
  src: url("https://fonts.googleapis.com/css?family=Noto+Sans|Noto+Sans+TC|Roboto");
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1,
.header__site-name {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 768px) {
  .layout-3col {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
}

@media (min-width: 768px) {
  .layout-3col {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-3col__full {
    clear: both;
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 10px;
    padding-right: 10px;
    padding: 0 58px;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
}

@media (min-width: 1280px) {
  .layout-3col {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding: 0;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 56.25%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 75%;
    margin-left: 18.75%;
    margin-right: -100%;
    clear: none;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 18.75%;
    margin-left: -100%;
  }
  .layout-3col__middle-content {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    clear: none;
  }
  [dir="rtl"] .layout-3col__middle-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: none;
    float: left;
    width: 12.5%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 25%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 6.25%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 6.25%;
    margin-left: 6.25%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 6.25%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 6.25%;
    margin-left: 12.5%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 12.5%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

@media (min-width: 1440px) {
  .layout-3col {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: right;
    width: 75%;
    margin-right: 0%;
    margin-left: -100%;
    clear: none;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__middle-content {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    clear: none;
  }
  [dir="rtl"] .layout-3col__middle-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: none;
    float: left;
    width: 16.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 29.16667%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 4.16667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 4.16667%;
    margin-left: 4.16667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 4.16667%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 4.16667%;
    margin-left: 8.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 8.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

body.front .layout-3col {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 0;
  padding-right: 0;
}

body.front .layout-3col:before {
  content: "";
  display: table;
}

body.front .layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

body.front .layout-3col__full, body.front .layout-3col__left-content, body.front .layout-3col__right-content, body.front .layout-3col__left-sidebar, body.front .layout-3col__right-sidebar, body.front .layout-3col__first-left-sidebar, body.front .layout-3col__second-left-sidebar, body.front .layout-3col__col-1, body.front .layout-3col__col-2, body.front .layout-3col__col-3, body.front .layout-3col__col-4, body.front .layout-3col__col-x {
  clear: both;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] body.front .layout-3col__full, [dir="rtl"] body.front .layout-3col__left-content, [dir="rtl"] body.front .layout-3col__right-content, [dir="rtl"] body.front .layout-3col__left-sidebar, [dir="rtl"] body.front .layout-3col__right-sidebar, [dir="rtl"] body.front .layout-3col__first-left-sidebar, [dir="rtl"] body.front .layout-3col__second-left-sidebar, [dir="rtl"] body.front .layout-3col__col-1, [dir="rtl"] body.front .layout-3col__col-2, [dir="rtl"] body.front .layout-3col__col-3, [dir="rtl"] body.front .layout-3col__col-4, [dir="rtl"] body.front .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

.hide_h1 h1 {
  display: none;
}

.layout-center {
  margin: 0 auto;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1280px) {
  .layout-center {
    max-width: 1100px;
  }
}

body.front .layout-center {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.layout-swap {
  padding-top: 0 !important;
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    z-index: -999;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix:before,
.header:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  clear: both;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

header a.header__logo {
  margin: 0;
  padding: 0;
}

header ul.menu {
  margin: 0;
  padding: 0;
}

header ul.menu li {
  margin: 0;
  padding: 0;
  list-style: none;
}

header ul.menu li a {
  text-decoration: none;
  color: #27499d;
  font-size: 18px;
  font-weight: bold;
}

.header-warp {
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 8px;
  height: 64px;
  box-sizing: border-box;
  background-color: #fff;
}

@media (min-width: 1280px) {
  .header-warp {
    height: 106px;
    padding: 20px 10px;
  }
}

.header-warp a.header__logo {
  float: none;
  margin: 0;
  padding: 0;
  display: block;
}

.header-warp a.header__logo img {
  display: block;
  width: 160px;
  height: 48px;
  max-width: 100%;
}

@media (min-width: 1280px) {
  .header-warp a.header__logo img {
    width: 220px;
    height: 67px;
  }
}

.header-warp .region-header {
  display: none;
}

@media (min-width: 1440px) {
  .header-warp .region-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
}

.header-warp .region-header ul.menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-warp .region-header ul.menu li {
  padding: 0 0 0 23.5px;
}

.header-warp .region-header ul.menu li a.active {
  color: #27499d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-warp .region-header ul.menu li a.active::before {
  content: '';
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 7px;
  background-image: url(/sites/all/themes/vdrops/images/menu-active.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.header-warp .mhb-bar {
  flex-basis: 50%;
  max-width: 50%;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 1440px) {
  .header-warp .mhb-bar {
    display: none;
  }
}

.header-warp .mhb-bar .warp {
  width: 32px;
  height: 22px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-warp .mhb-bar span {
  width: 100%;
  height: 3px;
  background-color: #27499d;
  display: block;
  border-radius: 2px;
  position: relative;
}

.header-warp .mhb-bar span::before, .header-warp .mhb-bar span::after {
  content: '';
  width: 100%;
  height: 3px;
  background-color: #27499d;
  display: block;
  border-radius: 5px;
  position: absolute;
  transform: rotate(0deg);
  transition: all .2s;
}

.header-warp .mhb-bar span::before {
  top: -10px;
}

.header-warp .mhb-bar span::after {
  top: 10px;
}

.header-warp .mhb-bar .mhb-open span {
  background-color: transparent;
}

.header-warp .mhb-bar .mhb-open span::before, .header-warp .mhb-bar .mhb-open span::after {
  top: 0;
}

.header-warp .mhb-bar .mhb-open span::before {
  transform: rotate(45deg);
}

.header-warp .mhb-bar .mhb-open span::after {
  transform: rotate(-45deg);
}

header {
  width: 100%;
}

header .rwd-menu {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: all .5s;
}

@media (min-width: 1280px) {
  header .rwd-menu {
    width: 360px;
    right: 0;
  }
}

header .rwd-menu ul.menu {
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 80%;
  overflow: auto;
}

header .rwd-menu ul.menu li {
  flex-basis: 50%;
  max-width: 50%;
  text-align: center;
  padding: 35px;
}

@media (min-width: 480px) {
  header .rwd-menu ul.menu li {
    flex-basis: 100%;
    max-width: 100%;
    padding: 24px 0;
  }
}

header .rwd-menu ul.menu li a.active {
  color: #000;
}

@media (min-width: 1280px) {
  header .rwd-menu ul.menu li a.active {
    color: #27499d;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header .rwd-menu ul.menu li a.active::before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 7px;
    background-image: url(/sites/all/themes/vdrops/images/menu-active.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

header .region-header #block-block-5 p {
  margin: 0;
  margin-left: 47px;
}

header #translateLink {
  margin: 14px 0;
  text-decoration: none;
  background-color: #27499d;
  color: #fff;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1440px) {
  header #translateLink {
    margin: 0;
  }
}

header .sl-fade {
  animation: go-sl .2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-direction: normal;
}

@keyframes go-sl {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#block-lang-dropdown-language-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
}

#block-lang-dropdown-language-content::before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(../images/icon/globe-americas@3x.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 5px;
}

#block-lang-dropdown-language-content select {
  outline: none;
  border: none;
  background-color: #eeeeee;
  width: 108px !important;
  height: 32px;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  padding-left: 2px;
}

/**商品列表上方區塊樣式**/
.content-top-region:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #84dbff;
}

.content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h h2.block__title {
  font-size: 36px;
  text-align: center;
  color: #27499d;
  line-height: 1.33;
  margin-top: 0;
  margin-bottom: 32px;
}

@media (min-width: 1280px) {
  .content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h h2.block__title {
    font-size: 48px;
  }
}

.content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h .field-name-field-product-block-image {
  display: none;
}

@media (min-width: 768px) {
  .content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h .field-name-field-product-block-image {
    display: block;
  }
}

.content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h .field-name-field-product-block-image .field-items {
  display: flex;
  justify-content: space-between;
}

.content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h .field-name-field-product-body .field-item {
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  color: #323237;
  margin-bottom: 44px;
  line-height: 36px;
}

@media (min-width: 1280px) {
  .content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h .field-name-field-product-body .field-item {
    font-size: 24px;
    line-height: 48px;
  }
}

.content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h .field-name-field-product-block-image .field-item:not(:nth-child(3)) {
  font-size: 18px;
  line-height: 2;
  text-align: center;
  color: #323237;
  margin-bottom: 53px;
  padding-right: 20px;
}

@media (min-width: 1280px) {
  .content-top-region #block-bean-shi-he-mei-ge-ren-de-jian-kang-h .field-name-field-product-block-image .field-item:not(:nth-child(3)) {
    padding-right: 100px;
  }
}

/**商品列表樣式**/
.page-product .layout-3col__full {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-product .layout-3col__full h1 {
  display: none;
}

.page-product .view-vdrops-product-view > h2 {
  margin: 64px 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 60px;
  border: 2px solid #84dbff;
  border-radius: 15px;
  background-color: #ffffff;
  font-size: 26px;
  text-decoration: none;
  text-align: center;
  color: #27499d;
  max-width: 228px;
}

@media (min-width: 480px) {
  .page-product .view-vdrops-product-view > h2 {
    padding: 5px 120px;
    padding-bottom: 2px;
    max-width: 348px;
  }
}

@media (min-width: 768px) {
  .page-product .view-vdrops-product-view > h2 {
    padding: 5px 99px;
    padding-bottom: 2px;
    max-width: 310px;
  }
}

.page-product .slick-prev,
.page-product .slick-next {
  width: 17px;
  height: 37px;
  border-radius: 0;
  background-size: 17px 37px;
}

.page-main .layout-3col__full > #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa h2.block__title,
#block-views-vdrops-product-view-block-1 h2.block__title {
  margin: 80px 50px 40px 50px;
  padding: 13px 0;
  border: 2px solid #84dbff;
  border-radius: 15px;
  background-color: #ffffff;
  text-decoration: none;
  text-align: center;
  color: #27499d;
  line-height: normal;
}

@media (min-width: 480px) {
  .page-main .layout-3col__full > #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa h2.block__title,
  #block-views-vdrops-product-view-block-1 h2.block__title {
    max-width: 348px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .page-main .layout-3col__full > #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa h2.block__title,
  #block-views-vdrops-product-view-block-1 h2.block__title {
    padding: 7px 31px;
    max-width: 310px;
    margin-bottom: 64px;
  }
}

#block-bean-xiao-fen-zi-tuan-shui-san-da-te- h2.block__title {
  margin-top: 19px;
  margin-bottom: 64px;
}

.view-vdrops-product-view > .view-content > .item-list > ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 48px;
}

.view-vdrops-product-view > .view-content > .item-list > .product-list > .views-row {
  list-style: none;
  flex-basis: 50%;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .view-vdrops-product-view > .view-content > .item-list > .product-list > .views-row {
    flex-basis: 33.3333%;
  }
}

@media (min-width: 1280px) {
  .view-vdrops-product-view > .view-content > .item-list > .product-list > .views-row {
    flex-basis: 24.9999%;
  }
}

.node-product .field-name-field-product-image,
.view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-field-product-image {
  display: flex;
  justify-content: center;
}

.node-product .field-name-title h2,
.view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-title h2 {
  text-align: center;
  font-size: 24px;
  line-height: 1.11;
  color: #27499d;
  margin-top: 30px;
  margin-bottom: 15px;
}

.node-product .field-name-field-product-narrative,
.view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-field-product-narrative {
  text-align: center;
  font-size: 18px;
  color: #27499d;
  margin-bottom: 5px;
}

.node-product .field-name-field-product-price,
.view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-field-product-price {
  text-align: center;
  font-size: 18px;
  color: #27499d;
}

.node-product .field-name-node-link,
.view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-node-link {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 48px;
}

.node-product .field-name-node-link .field-items .field-item a,
.view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-node-link .field-items .field-item a {
  padding: 9px 21px;
  border: 2px solid #84dbff;
  border-radius: 10px;
  background-color: #ffffff;
  text-decoration: none;
  color: #7ecdeb;
}

@media (min-width: 1280px) {
  .node-product .field-name-node-link .field-items .field-item a,
  .view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-node-link .field-items .field-item a {
    border-radius: 15px;
    padding: 6px 29px;
  }
}

.node-product .field-name-node-link .field-items .field-item a:hover,
.view-vdrops-product-view > .view-content > .item-list > ul > .views-row .field-name-node-link .field-items .field-item a:hover {
  background-color: #84dbff;
  color: #ffffff;
}

#block-views-vdrops-product-view-block-1 {
  max-width: 1243px;
  margin: 0 auto;
}

.view-vdrops-product-view > .view-content .slick__slider {
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
}

.slick-views-vdrops-product-view-2-slider .slick-list {
  width: 100%;
  padding: 0;
}

#slick-views-vdrops-product-view-2 .slick__slide {
  outline: 0;
  border: 0;
  padding: 0 15px;
}

/**SlideShow Arrow樣式**/
.slick__arrow .slick-prev {
  background-image: url("../images/icon/arrow-left.png");
  left: 10px;
  top: -120px;
}

.slick__arrow .slick-prev::before {
  color: unset;
  content: unset;
}

.slick__arrow .slick-next {
  background-image: url("../images/icon/arrow-right.png");
  right: 10px;
  top: -120px;
}

.slick__arrow .slick-next::before {
  color: unset;
  content: unset;
}

.slick__arrow .slick-prev,
.slick__arrow .slick-next {
  width: 17px;
  height: 37px;
  border-radius: 0;
  background-size: 17px 37px;
}

.node-product .field-name-node-link {
  margin-bottom: 91px;
}

@media (min-width: 768px) {
  .node-product .field-name-node-link {
    margin-bottom: 131px;
  }
}

/**PAGER樣式**/
.pager {
  margin: 72px -25px;
}

.pager > li {
  padding: 0 18px;
}

.pager > li > a {
  text-decoration: none;
  font-size: 20px;
  color: #27499d;
  padding: 15px 21px;
}

.pager > li > a:hover {
  background-color: #eeeeee;
  border-radius: 999em;
}

.pager .pager-current {
  font-size: 20px;
  color: #323237;
  font-weight: normal;
}

.pager .pager-first a {
  width: 11px;
  color: transparent;
  background: url("../images/icon/page-first.png") no-repeat center;
}

.pager .pager-first a:hover {
  color: transparent;
  background: url("../images/icon/page-first-hover.png") no-repeat center;
}

.pager .pager-previous a {
  width: 8px;
  color: transparent;
  background: url("../images/icon/page-back.png") no-repeat center;
}

.pager .pager-previous a:hover {
  color: transparent;
  background: url("../images/icon/page-back-hover.png") no-repeat center;
}

.pager .pager-next a {
  width: 8px;
  color: transparent;
  background: url("../images/icon/page-next.png") no-repeat center;
}

.pager .pager-next a:hover {
  color: transparent;
  background: url("../images/icon/page-next-hover.png") no-repeat center;
}

.pager .pager-last a {
  width: 11px;
  color: transparent;
  background: url("../images/icon/page-last.png") no-repeat center;
}

.pager .pager-last a:hover {
  color: transparent;
  background: url("../images/icon/page-last-hover.png") no-repeat center;
}

.region-bottom p {
  margin: 0;
  padding: 0;
}

.region-bottom a {
  text-decoration: none;
}

.region-bottom ul.menu {
  margin: 0;
  padding: 0;
}

.region-bottom ul.menu li {
  list-style: none;
}

@media (min-width: 1280px) {
  .region-bottom .info-warp {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 50px;
  }
}

@media (min-width: 1280px) and (min-width: 1440px) {
  .region-bottom .info-warp {
    max-width: 1440px;
  }
}

.region-bottom .info-warp .info-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 10px;
}

@media (min-width: 1280px) {
  .region-bottom .info-warp .info-block {
    padding: 0;
    flex-basis: 30%;
    align-items: flex-start;
    max-width: 30%;
  }
}

.region-bottom .info-warp .info-block .view-id-vdrops_footer_block ul {
  padding: 0;
  margin: 0;
}

.region-bottom .info-warp .info-block .view-id-vdrops_footer_block ul li {
  list-style: none;
}

.region-bottom .info-warp .info-block p,
.region-bottom .info-warp .info-block a {
  color: #27499d;
  font-size: 18px;
  font-weight: normal;
}

.region-bottom .info-warp .info-block p {
  margin: 6px 0;
}

.region-bottom .info-warp .info-block > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 1280px) {
  .region-bottom .info-warp .info-block > div {
    align-items: flex-start;
  }
}

.region-bottom .info-warp .info-block .media-element-container {
  margin-bottom: 31px;
}

.region-bottom .info-warp .info-block .media-element-container > div .content {
  width: 108px;
  height: 97px;
}

@media (min-width: 1280px) {
  .region-bottom .info-warp .info-block .media-element-container > div .content {
    width: 140px;
    height: 125px;
  }
}

.region-bottom .info-warp .info-block .media-element-container > div .content img {
  display: block;
  max-width: 100%;
}

.region-bottom .info-warp .info-block .community-warp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 29px;
}

@media (min-width: 1280px) {
  .region-bottom .info-warp .info-block .community-warp {
    margin-top: 13px;
  }
}

.region-bottom .info-warp .info-block .community-warp a {
  display: block;
  width: 36px;
  height: 36px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 10px;
}

@media (min-width: 1280px) {
  .region-bottom .info-warp .info-block .community-warp a {
    margin: 0 8px 0 0;
  }
}

.region-bottom .info-warp .info-block .community-warp .line {
  background-image: url(/sites/all/themes/vdrops/images/icon/sns-line@3x.png);
}

.region-bottom .info-warp .info-block .community-warp .fb {
  background-image: url(/sites/all/themes/vdrops/images/icon/sns-facebook@3x.png);
}

.region-bottom .info-warp .menu-block {
  display: none;
}

@media (min-width: 1280px) {
  .region-bottom .info-warp .menu-block {
    display: block;
    flex-basis: 70%;
    max-width: 70%;
  }
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu a {
  color: #180001;
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu li.expanded {
  width: 160px;
  margin: 0 20px;
}

@media (min-width: 1440px) {
  .region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu li.expanded {
    margin: 0 40px;
  }
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu li.expanded > a {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  color: #180001;
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu li.expanded > a::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu li.expanded ul.menu {
  margin-top: 40px;
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu li.expanded ul.menu li {
  padding: 5px 0;
}

.region-bottom .info-warp .menu-block #block-menu-menu-menu-sitemap-menu > ul.menu li.expanded ul.menu li a {
  font-size: 16px;
}

.region-bottom .copy {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  background-color: #27499d;
  padding: 10px 18px;
  text-align: center;
  margin-top: 40px;
}

@media (min-width: 1280px) {
  .region-bottom .copy {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.region-footer {
  margin-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 300px;
  background-image: url(/sites/all/themes/vdrops/images/contact-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 1280px) {
  .region-footer {
    height: 363px;
  }
}

@media (min-width: 1440px) {
  .region-footer {
    height: 544px;
  }
}

.region-footer::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 1;
}

.region-footer > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

@media (min-width: 768px) {
  .region-footer > div {
    padding: 0;
    margin: 0 auto;
  }
}

.region-footer > div h2 {
  display: none;
}

.region-footer > div .bean-contact-us-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.region-footer > div .field-name-title-field .field-item {
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  z-index: 5;
  text-align: center;
  line-height: normal;
}

@media (min-width: 1280px) {
  .region-footer > div .field-name-title-field .field-item {
    font-size: 30px;
  }
}

.region-footer > div .field-type-link-field a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  width: 160px;
  height: 40px;
  margin-top: 40px;
  border: 3px solid #84dbff;
  background-color: transparent;
  border-radius: 15px;
  position: relative;
  z-index: 5;
}

@media (min-width: 1280px) {
  .region-footer > div .field-type-link-field a {
    font-size: 24px;
    width: 300px;
    height: 50px;
    margin-top: 80px;
  }
}

@media (min-width: 1440px) {
  body:not(.front) .region-footer {
    height: 240px;
  }
}

@media (min-width: 1440px) {
  body:not(.front) .region-footer > div .bean-contact-us-block {
    max-width: 100%;
    flex-direction: row;
  }
}

@media (min-width: 1440px) {
  body:not(.front) .region-footer > div .field-type-link-field a {
    margin-top: 0;
    margin-left: 180px;
  }
}

body.front .region-footer {
  margin-top: 0;
}

.delay-half {
  animation-delay: .5s;
}

@-webkit-keyframes fadeFromLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeFromLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeFromLeft {
  -webkit-animation-name: fadeFromLeft;
  animation-name: fadeFromLeft;
}

@-webkit-keyframes fadeFromRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeFromRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeFromRight {
  -webkit-animation-name: fadeFromRight;
  animation-name: fadeFromRight;
}

.breadcrumb {
  padding-left: 10px;
  margin: 0 auto;
  margin-bottom: 47px;
}

@media (min-width: 1280px) {
  .breadcrumb {
    max-width: 1100px;
  }
}

.breadcrumb span,
.breadcrumb a {
  text-decoration: none;
  color: #7d7d7d;
  font-size: 14px;
}

.breadcrumb .delimiter {
  font-size: 9px;
}

#block-views-vdrops-slideshow-block .slick-dots {
  display: none !important;
  margin: 0;
  margin-bottom: 8px;
  bottom: 0;
}

@media (min-width: 1440px) {
  #block-views-vdrops-slideshow-block .slick-dots {
    display: block !important;
  }
}

#block-views-vdrops-slideshow-block button {
  padding: 0;
}

#block-views-vdrops-slideshow-block button::before {
  color: #ffffff;
  font-size: 16px;
  opacity: 1;
  transition: all .5s;
}

#block-views-vdrops-slideshow-block .slick-active button::before {
  color: #27499d;
  opacity: 1;
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 50px 50px;
}

@media (min-width: 480px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa {
    padding: 20px 76px 50px;
  }
}

@media (min-width: 666px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa {
    padding: 20px 58px 79px;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa h2.block__title {
  color: #27499d;
  font-size: 26px;
  font-weight: bold;
  border-radius: 15px;
  border: 2px solid #84dbff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  margin-bottom: 40px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa h2.block__title {
    max-width: 360px !important;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-views-frontpage-featured-block {
  max-width: 1420px;
  width: 100%;
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul {
    flex-direction: row;
    align-items: flex-start;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 33.3333%;
  position: relative;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li {
    margin-bottom: 60px;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-title,
#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-title-field {
  color: #84dbff;
  font-size: 30px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
}

@media (min-width: 1280px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-title,
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-title-field {
    font-size: 26px;
    line-height: 32px;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li img {
  display: block;
  max-width: 100%;
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-wart-image {
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-wart-image {
    margin-bottom: 14px;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-active-image {
  display: none;
  position: absolute;
  top: 0;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-active-image {
    margin-bottom: 14px;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-feature-content {
  display: block;
  margin-top: 30px;
  text-align: center;
  font-size: 22px;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-feature-content {
    display: none;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-feature-content p {
  margin: 0;
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-active-image,
#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul li .views-field-field-wart-image {
  width: 57px;
  height: 80px;
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::before, #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::after {
  content: '';
  display: none;
  width: 50%;
  height: 1px;
  background-color: #84dbff;
  position: absolute;
  top: 45%;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::before, #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::after {
    display: block;
  }
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::before {
    left: -65px;
  }
}

@media (min-width: 1280px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::before {
    left: -150px;
  }
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::after {
    right: -65px;
  }
}

@media (min-width: 1280px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list ul .views-row-2::after {
    right: -150px;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list .drop-active-content-warp {
  display: none;
  width: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list .drop-active-content-warp {
    display: block;
  }
}

#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list .drop-active-content-warp .style {
  font-size: 22px;
  font-weight: normal;
  color: #000000;
  text-align: center;
  line-height: normal;
}

@media (min-width: 1280px) {
  #block-views-8f5469fed0e9ea761ea3ee66a3edc0aa .view-content .item-list .drop-active-content-warp .style {
    font-size: 26px;
  }
}

.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu {
  margin-bottom: 30px;
}

@media (min-width: 1440px) {
  .node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
  .node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu,
  .node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
  .node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu,
  .page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
  .page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu,
  .page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu,
  .page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
  }
}

.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu .block__title,
.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu .block__title,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu .block__title,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu .block__title,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu .block__title,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu .block__title,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu .block__title,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu .block__title {
  display: none;
}

.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media (min-width: 1280px) {
  .node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
  .node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu,
  .node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
  .node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu,
  .page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
  .page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu,
  .page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu,
  .page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li {
  list-style: none;
}

@media (min-width: 1280px) {
  .node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
  .node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li,
  .node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
  .node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li,
  .page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
  .page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li,
  .page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li,
  .page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li {
    width: 100%;
    border-right: 1px solid #eee;
  }
}

.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a,
.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a {
  font-size: 18px;
  font-weight: bold;
  padding: 16px;
  text-decoration: none;
  color: #323237;
  display: block;
}

.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a.active,
.node-type-about-us aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a.active,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a.active,
.node-type-test-report aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a.active,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a.active,
.page-qa aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a.active,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-about-us-menu ul.menu li a.active,
.page-taxonomy-term aside.layout-3col__left-sidebar #block-menu-menu-qa-menu ul.menu li a.active {
  background-color: #eee;
}

.node-type-about-us .node .field-name-title h2,
.node-type-test-report .node .field-name-title h2,
.page-qa .node .field-name-title h2,
.page-taxonomy-term .node .field-name-title h2 {
  margin: 0;
  text-align: left;
  color: #27499d;
  font-size: 36px;
  font-weight: bold;
}

.node-type-about-us .node,
.node-type-test-report .node {
  border-bottom: 1px solid #84dbff;
}

.node-type-about-us .pager-warp,
.node-type-test-report .pager-warp {
  margin-top: 15px;
  position: relative;
  height: 26px;
}

.node-type-about-us .pager-warp > div,
.node-type-test-report .pager-warp > div {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0;
}

.node-type-about-us .pager-warp .pager-back,
.node-type-test-report .pager-warp .pager-back {
  left: 0;
}

.node-type-about-us .pager-warp .pager-back a::before,
.node-type-test-report .pager-warp .pager-back a::before {
  content: '';
  display: block;
  background-image: url(/sites/all/themes/vdrops/images/icon/page-back.png);
  margin-right: 15px;
}

.node-type-about-us .pager-warp .pager-next,
.node-type-test-report .pager-warp .pager-next {
  padding: 0;
  right: 0;
}

.node-type-about-us .pager-warp .pager-next a::after,
.node-type-test-report .pager-warp .pager-next a::after {
  content: '';
  display: block;
  background-image: url(/sites/all/themes/vdrops/images/icon/page-next.png);
  margin-left: 15px;
}

.node-type-about-us .pager-warp a,
.node-type-test-report .pager-warp a {
  color: #27499d;
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.node-type-about-us .pager-warp a::after, .node-type-about-us .pager-warp a::before,
.node-type-test-report .pager-warp a::after,
.node-type-test-report .pager-warp a::before {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
}

.entity_pager {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
  padding-top: 16px !important;
  border-top: 1px solid #84dbff;
}

.entity_pager .entity_pager_count {
  display: none;
}

.entity_pager li .inactive {
  display: none;
}

.entity_pager li a {
  font-size: 18px;
  color: #27499d;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.entity_pager .entity_pager_next a::after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  border: 3px solid #27499d;
  border-left: 0;
  border-bottom: 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-left: 16px;
}

.entity_pager .entity_pager_prev a::before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  border: 3px solid #27499d;
  border-right: 0;
  border-top: 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-right: 16px;
}

.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items .field-item .field-name-field-paragraph-image a,
.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items .field-item .field-name-field-paragraph-image img {
  display: block;
}

.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items .field-item .field-name-field-paragraph-image .field-items .field-item {
  display: none;
}

.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items .field-item .field-name-field-paragraph-image .field-items .field-item:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #000;
}

.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items .field-item .field-name-field-paragraph-image .field-items .field-item:first-child img {
  opacity: 1;
  transition: all .3s;
}

.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items .field-item .field-name-field-paragraph-image .field-items .field-item:first-child:hover img {
  opacity: .5;
}

.node-type-test-report .paragraphs-items .field-name-field-content-introduce .field-items .field-item .field-name-field-paragraph-image .field-items .field-item:first-child:hover::after {
  content: '放大顯示 + ';
  display: block;
  position: absolute;
  text-align: center;
  color: #ffffff;
  top: 45%;
}

.view-id-vdrops_qa_view {
  margin-top: 34px;
}

@media (min-width: 768px) {
  .view-id-vdrops_qa_view {
    margin-top: 49px;
  }
}

@media (min-width: 1280px) {
  .view-id-vdrops_qa_view {
    margin-top: 0;
  }
}

.view-id-vdrops_qa_view .item-list ul {
  padding: 0;
  margin: 0;
}

.view-id-vdrops_qa_view .item-list ul li {
  list-style: none;
  border-bottom: 1px solid #eee;
  margin-bottom: 33px;
  padding-bottom: 40px;
}

.view-id-vdrops_qa_view .item-list ul li > div .field-name-title h2 {
  color: #27499d;
  font-size: 36px;
  font-weight: bold;
  margin: 0;
}

.view-id-vdrops_qa_view .item-list ul li > div .field-name-body {
  font-size: 18px;
  font-weight: normal;
  color: #323237;
  margin: 23px 0 26px;
}

.view-id-vdrops_qa_view .item-list ul li > div .field-name-node-link a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #27499d;
}

.view-id-vdrops_qa_view .item-list ul li:last-child {
  border: none;
}

.page-qa .pager {
  margin-bottom: 15px !important;
}

.node-type-qa .field-name-title h2,
.node-type-news .field-name-title h2 {
  color: #27499d;
  font-size: 36px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 24px;
}

.node-type-qa .tax-warp,
.node-type-news .tax-warp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.node-type-qa .tax-warp .field-name-post-date,
.node-type-news .tax-warp .field-name-post-date {
  margin-right: 10px;
}

.node-type-qa .tax-warp .field-name-post-date,
.node-type-qa .tax-warp .field-name-field-news-tax,
.node-type-news .tax-warp .field-name-post-date,
.node-type-news .tax-warp .field-name-field-news-tax {
  font-size: 16px;
  font-weight: bold;
  color: #27499d;
}

.node-type-qa .field-name-addtoany,
.node-type-news .field-name-addtoany {
  margin-bottom: 26px;
}

.node-type-qa aside.layout-3col__right-sidebar h2.block__title,
.node-type-news aside.layout-3col__right-sidebar h2.block__title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #27499d;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.node-type-qa aside.layout-3col__right-sidebar h2.block__title::before,
.node-type-news aside.layout-3col__right-sidebar h2.block__title::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #84daff;
  margin-right: 10px;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views ul,
.node-type-news aside.layout-3col__right-sidebar .block-views ul {
  padding: 0;
  margin: 0;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views ul li,
.node-type-news aside.layout-3col__right-sidebar .block-views ul li {
  margin: 10px 0;
  list-style: none;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views ul li .node-qa,
.node-type-news aside.layout-3col__right-sidebar .block-views ul li .node-qa {
  display: flex;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-type-image,
.node-type-news aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-type-image {
  max-width: 100%;
  padding: 0;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title,
.node-type-news aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title {
  padding-left: 20px;
  flex-basis: 59.9999%;
}

@media (min-width: 1280px) {
  .node-type-qa aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title,
  .node-type-news aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title {
    padding-left: 13px;
  }
}

.node-type-qa aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title h2,
.node-type-news aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title h2 {
  margin: 0;
  line-height: 1.22;
  font-size: 18px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title a,
.node-type-news aside.layout-3col__right-sidebar .block-views ul li .node-qa .field-name-title a {
  color: #27499d;
  text-decoration: none;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_qa_view .item-list h2,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_qa_view .item-list h2 {
  margin: 0;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_qa_view .item-list h2 a,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_qa_view .item-list h2 a {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #27499d;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list,
  .node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -15px;
  }
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list li,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list li {
  padding: 15px 0;
}

@media (min-width: 768px) {
  .node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list li,
  .node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list li {
    flex-basis: 50%;
    max-width: 50%;
    margin: 0;
    padding: 15px;
  }
}

@media (min-width: 1280px) {
  .node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list li,
  .node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list ul.news_view_list li {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .field-type-image,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .field-type-image {
  flex-basis: 35%;
  max-width: 35%;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .field-type-image img,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .field-type-image img {
  display: block;
  max-width: 100%;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp {
  flex-basis: 65%;
  max-width: 65%;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp,
  .node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp {
    padding-left: 20px;
  }
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp .field-name-title h2,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp .field-name-title h2 {
  margin: 0;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp .field-name-title h2 a,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .content-warp .field-name-title h2 a {
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #27499d;
  line-height: 1;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp .field-name-post-date,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp .field-name-post-date {
  margin-right: 10px;
}

.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp .field-name-post-date,
.node-type-qa aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp .field-name-field-news-tax,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp .field-name-post-date,
.node-type-news aside.layout-3col__right-sidebar .block-views .view-id-vdrops_news_view .item-list .node .tax-warp .field-name-field-news-tax {
  font-size: 14px;
  font-weight: bold;
  color: #27499d;
}

.node-type-news .field-name-title h2 {
  margin-bottom: 0px;
}

.page-benefit h1,
.page-what-is h1,
.page-our-water h1 {
  margin-bottom: 34px;
  color: #27499d;
  font-size: 36px;
  text-align: center;
}

@media (min-width: 769px) {
  .page-benefit h1,
  .page-what-is h1,
  .page-our-water h1 {
    font-size: 48px;
    margin-bottom: 48px;
  }
}

.page-benefit .view-header p,
.page-what-is .view-header p,
.page-our-water .view-header p {
  margin: 0;
  margin-bottom: 36px;
  font-size: 18px;
  color: #323237;
  text-align: center;
  line-height: normal;
  line-height: 36px;
}

@media (min-width: 769px) {
  .page-benefit .view-header p,
  .page-what-is .view-header p,
  .page-our-water .view-header p {
    font-size: 24px;
    margin-bottom: 70px;
    line-height: 48px;
  }
}

.page-benefit .view-content .item-list > ul,
.page-what-is .view-content .item-list > ul,
.page-our-water .view-content .item-list > ul {
  margin: 0;
  padding: 0;
}

.page-benefit .view-content .item-list > ul > li,
.page-what-is .view-content .item-list > ul > li,
.page-our-water .view-content .item-list > ul > li {
  position: relative;
  list-style: none;
  margin-bottom: 24px;
}

.page-benefit .view-content .item-list > ul > li .views-field-title,
.page-benefit .view-content .item-list > ul > li .views-field-title-field,
.page-what-is .view-content .item-list > ul > li .views-field-title,
.page-what-is .view-content .item-list > ul > li .views-field-title-field,
.page-our-water .view-content .item-list > ul > li .views-field-title,
.page-our-water .view-content .item-list > ul > li .views-field-title-field {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  padding: 0 31px;
}

.page-benefit .view-content .item-list > ul > li .views-field-title a,
.page-benefit .view-content .item-list > ul > li .views-field-title-field a,
.page-what-is .view-content .item-list > ul > li .views-field-title a,
.page-what-is .view-content .item-list > ul > li .views-field-title-field a,
.page-our-water .view-content .item-list > ul > li .views-field-title a,
.page-our-water .view-content .item-list > ul > li .views-field-title-field a {
  font-size: 24px;
  text-decoration: none;
  color: #fff;
  text-shadow: 3px 2px 6px #000000;
  display: block;
  line-height: normal;
}

@media (min-width: 769px) {
  .page-benefit .view-content .item-list > ul > li .views-field-title a,
  .page-benefit .view-content .item-list > ul > li .views-field-title-field a,
  .page-what-is .view-content .item-list > ul > li .views-field-title a,
  .page-what-is .view-content .item-list > ul > li .views-field-title-field a,
  .page-our-water .view-content .item-list > ul > li .views-field-title a,
  .page-our-water .view-content .item-list > ul > li .views-field-title-field a {
    font-size: 36px;
  }
}

.page-benefit .view-content .item-list > ul > li .views-field-title a img,
.page-benefit .view-content .item-list > ul > li .views-field-title-field a img,
.page-what-is .view-content .item-list > ul > li .views-field-title a img,
.page-what-is .view-content .item-list > ul > li .views-field-title-field a img,
.page-our-water .view-content .item-list > ul > li .views-field-title a img,
.page-our-water .view-content .item-list > ul > li .views-field-title-field a img {
  display: block;
  width: 100%;
}

.node-type-benefit .node .field-name-title h2,
.node-type-what-is .node .field-name-title h2,
.node-type-our-water .node .field-name-title h2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 36px;
  color: #27499d;
}

#block-views-vdrops-news-view-block-1 {
  background-color: #eeeeee;
  padding: 60px 50px 80px 50px;
}

@media (min-width: 480px) {
  #block-views-vdrops-news-view-block-1 {
    padding: 60px 70px 80px 70px;
  }
}

@media (min-width: 768px) {
  #block-views-vdrops-news-view-block-1 {
    padding: 60px 58px 80px 58px;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block-1 {
    padding: 60px 90px 80px 90px;
  }
}

#block-views-vdrops-news-view-block-1 .block__title {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  font-size: 26px;
  color: #27499d;
  text-align: center;
}

@media (min-width: 768px) {
  #block-views-vdrops-news-view-block-1 .block__title {
    margin-bottom: 40px;
  }
}

#block-views-vdrops-news-view-block-1 .view-content {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block-1 .view-content {
    max-width: 1100px;
  }
}

@media (min-width: 1440px) {
  #block-views-vdrops-news-view-block-1 .view-content {
    max-width: 1420px;
  }
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul {
  padding: 0;
  margin: -12px 0;
}

@media (min-width: 666px) {
  #block-views-vdrops-news-view-block-1 .view-content .item-list > ul {
    display: flex;
    margin: 0 -10px;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block-1 .view-content .item-list > ul {
    margin: 0px -50px;
  }
}

@media (min-width: 1440px) {
  #block-views-vdrops-news-view-block-1 .view-content .item-list > ul {
    margin: 0px -70px;
  }
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li {
  list-style: none;
  padding: 15px 0;
}

@media (min-width: 666px) {
  #block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li {
    flex-basis: 33.33%;
    max-width: 33.33%;
    padding: 0 10px;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li {
    padding: 0px 50px;
  }
}

@media (min-width: 1440px) {
  #block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li {
    padding: 0px 70px;
  }
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .field-name-field-news-image {
  padding-bottom: 15px;
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .field-name-field-news-image a {
  display: block;
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .field-name-field-news-image a img {
  display: block;
  width: 100%;
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .content-warp .field-name-title {
  padding-bottom: 12px;
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .content-warp .field-name-title .field-item h2 {
  margin: 0;
  margin-top: 8px;
  line-height: 28px;
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .content-warp .field-name-title .field-item h2 a {
  text-decoration: none;
  color: #27499d;
  font-size: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .content-warp .tax-warp {
  display: flex;
  margin-top: 0px;
  font-size: 16px;
  color: #27499d;
}

#block-views-vdrops-news-view-block-1 .view-content .item-list > ul > li .content-warp .tax-warp .field-name-field-news-tax {
  padding-left: 5px;
}

.node-type-contact .field-name-body::after {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: #84dbff;
  margin: 47px 0 32px;
}

.node-type-contact .field-name-body .field-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 768px) {
  .node-type-contact .field-name-body .field-item {
    align-items: flex-start;
    flex-direction: row;
  }
}

.node-type-contact .field-name-body .field-item > p {
  margin: 0;
  width: 160px;
  height: 144px;
}

@media (min-width: 768px) {
  .node-type-contact .field-name-body .field-item > p {
    width: 124px;
    height: 111px;
    margin-right: 68px;
  }
}

@media (min-width: 1280px) {
  .node-type-contact .field-name-body .field-item > p {
    width: 220px;
    height: 197px;
    margin-right: 100px;
  }
}

.node-type-contact .field-name-body .field-item img {
  display: block;
  max-width: 100%;
}

.node-type-contact .field-name-body .field-item .info-warp {
  text-align: center;
}

@media (min-width: 768px) {
  .node-type-contact .field-name-body .field-item .info-warp {
    text-align: left;
  }
}

.node-type-contact .field-name-body .field-item .info-warp h2.title {
  font-size: 36px;
  font-weight: bold;
  color: #27499d;
  margin: 0;
}

.node-type-contact .field-name-body .field-item .info-warp p {
  font-size: 18px;
  font-weight: normal;
  color: #323237;
}

.node-type-contact .field-name-body .field-item .info-warp a {
  text-decoration: none;
  color: #323237;
}

.node-type-contact .field-name-body .field-item .info-warp .community-warp > div {
  padding: 10px 0;
}

.node-type-contact .field-name-body .field-item .info-warp .community-warp > div a,
.node-type-contact .field-name-body .field-item .info-warp .community-warp > div p {
  margin: 0;
  padding: 0;
  display: block;
}

.node-type-contact .field-name-body .field-item .info-warp .community-warp > div a {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .node-type-contact .field-name-body .field-item .info-warp .community-warp > div a {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.node-type-contact .field-name-body .field-item .info-warp .community-warp > div a::after {
  content: '';
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.node-type-contact .field-name-body .field-item .info-warp .community-warp .line-warp a::after {
  content: '';
  background-image: url(/sites/all/themes/vdrops/images/icon/find-us-line@3x.png);
  width: 90px;
  height: 32px;
}

.node-type-contact .field-name-body .field-item .info-warp .community-warp .fb-warp a::after {
  content: '';
  background-image: url(/sites/all/themes/vdrops/images/icon/find-us-fb@3x.png);
  width: 138px;
  height: 32px;
}

.node-type-contact form.webform-client-form > div .form-required,
.node-type-contact form.webform-client-form > div .grippie {
  display: none;
}

.node-type-contact form.webform-client-form > div::before {
  content: '連絡表單';
  display: block;
  color: #27499d;
  font-size: 24px;
  font-weight: bold;
}

.node-type-contact form.webform-client-form > div .webform-progressbar {
  display: none;
}

.node-type-contact form.webform-client-form > div fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.node-type-contact form.webform-client-form > div fieldset legend {
  display: none;
}

.node-type-contact form.webform-client-form > div fieldset .fieldset-legend {
  font-size: 18px;
  font-weight: normal;
  color: #323237;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) {
  position: relative;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) span {
  position: absolute;
  top: -11px;
  left: 10px;
  background-color: #fff;
  font-size: 14px;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) label {
  display: none;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) input,
.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) textarea {
  border: 1px solid #7d7d7d;
  border-radius: 5px;
  outline: none;
  padding: 15px 20px 10px;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) input:focus,
.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) textarea:focus {
  border: 1px solid #27499d;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) input:focus ~ span {
  color: #27499d;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) textarea:focus ~ span {
  color: #27499d;
}

.node-type-contact form.webform-client-form > div .form-item:not(.webform-component-select) [for=edit-submitted-privacy] {
  display: none;
}

.node-type-contact form.webform-client-form > div .webform-component--tel span {
  left: 10px !important;
}

@media (min-width: 768px) {
  .node-type-contact form.webform-client-form > div .webform-component--tel span {
    left: 20px !important;
  }
}

.node-type-contact form.webform-client-form > div .webform-component-select {
  position: relative;
}

.node-type-contact form.webform-client-form > div .webform-component-select::after {
  content: '';
  position: absolute;
  top: 22px;
  right: 20px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 7.5px 0 7.5px;
  border-color: #000000 transparent transparent transparent;
}

.node-type-contact form.webform-client-form > div .webform-component-select label {
  display: none;
}

.node-type-contact form.webform-client-form > div .webform-component-select select {
  background-color: #fff;
  border: 1px solid #7d7d7d;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 52px;
  padding: 0 20px;
  appearance: none;
}

.node-type-contact form.webform-client-form > div .form-checkboxes .form-type-checkbox input {
  border: none;
  outline: none;
  padding: 0;
}

.node-type-contact form.webform-client-form > div .form-checkboxes .form-type-checkbox label {
  display: inline;
  position: static;
  top: 0;
  left: 0;
  background-color: transparent;
}

.node-type-contact form.webform-client-form > div .form-checkboxes .form-type-checkbox label a {
  text-decoration: none;
  color: #27499d;
}

.node-type-contact form.webform-client-form .form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.node-type-contact form.webform-client-form .form-actions input {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  outline: none;
  display: block;
  margin: 0 10px;
}

.node-type-contact form.webform-client-form .form-actions .form-submit {
  background-color: #27499d;
}

.node-type-contact form.webform-client-form .form-actions .clear {
  background-color: #c2c2c2;
}

.i18n-en.node-type-contact form.webform-client-form > div::before {
  content: 'Forms';
  display: block;
  color: #27499d;
  font-size: 24px;
  font-weight: bold;
}

.i18n-en.node-type-contact form.webform-client-form .form-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.i18n-en.node-type-contact form.webform-client-form .form-actions input {
  width: 173px;
  height: 40px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  outline: none;
  display: block;
  margin: 0 10px;
}

.i18n-en.node-type-contact form.webform-client-form .form-actions .form-submit {
  background-color: #27499d;
}

.i18n-en.node-type-contact form.webform-client-form .form-actions .clear {
  background-color: #c2c2c2;
}

.i18n-ja.node-type-contact form.webform-client-form > div::before {
  content: 'お問い合わせフォーム';
  display: block;
  color: #27499d;
  font-size: 24px;
  font-weight: bold;
}

.page-news #block-views-vdrops-news-view-block-2 {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 {
    max-width: 1100px;
  }
}

.page-news #block-views-vdrops-news-view-block-2 > h2 {
  margin-bottom: 24px;
  color: #27499d;
  font-size: 36px;
  text-align: center;
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 > h2 {
    font-size: 48px;
    margin-bottom: 48px;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-prev {
  background-image: url("../images/icon/arrow-left.png");
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-prev {
    left: -45px;
    top: 0;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-prev::before {
  color: unset;
  content: unset;
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-next {
  background-image: url("../images/icon/arrow-right.png");
  top: 50%;
  transform: translateY(-50%);
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-next {
    right: -45px;
    top: 0;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-next::before {
  color: unset;
  content: unset;
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-prev,
.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__arrow .slick-next {
  width: 17px;
  height: 37px;
  border-radius: 0;
  background-size: 17px 37px;
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider {
  margin-top: 10px;
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide {
  display: flex;
  justify-content: center;
  outline: 0;
  padding: 0 24px;
}

@media (min-width: 480px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide {
    padding: 0 32px;
  }
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide {
    padding: 0 50px;
  }
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide {
    background-color: #eeeeee;
    padding: 0;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

@media (min-width: 480px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 {
    margin: 0;
    padding: 0 60px;
  }
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 {
    flex-direction: row;
    padding: 16px 0;
  }
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .image-cover-3 {
    flex-basis: 50%;
    padding-left: 10px;
  }
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .image-cover-3 {
    flex-basis: 50%;
    max-width: 502px;
    padding: 16px;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .image-cover-3 .field-name-field-news-image a img {
  width: 100%;
  display: block;
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper {
    flex-basis: 50%;
    padding-left: 21px;
  }
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper {
    padding: 16px;
    padding-bottom: 0;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .title {
  padding: 15px 0 11px 0;
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .title {
    padding: 0 0 11px 0;
  }
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .title {
    padding: 0 0 18px 0;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .title h2 {
  margin: 0;
  line-height: 28px;
  font-weight: normal;
  font-weight: bold;
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .title h2 {
    padding: 0;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .title h2 a {
  text-decoration: none;
  color: #27499d;
  font-size: 24px;
}

@media (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .title h2 a {
    font-size: 36px;
    line-height: 40px;
  }
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .date-taxonomy {
  display: flex;
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .date-taxonomy .field-name-post-date {
  font-size: 16px;
  color: #27499d;
  font-weight: bold;
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .date-taxonomy .field-name-field-news-tax {
  font-size: 16px;
  color: #27499d;
  font-weight: bold;
  padding-left: 5px;
}

.page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .body-link {
  display: none;
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .body-link {
    display: block;
    font-size: 16px;
    padding-top: 17px;
  }
}

@media (min-width: 768px) and (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .body-link {
    font-size: 18px;
    padding-top: 31px;
  }
}

@media (min-width: 768px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .body-link .field-name-node-link {
    display: none;
  }
}

@media (min-width: 768px) and (min-width: 1280px) {
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .body-link .field-name-node-link {
    display: block;
    padding-top: 29px;
  }
  .page-news #block-views-vdrops-news-view-block-2 .view-display-id-block_2 .slick__slider .slick-list .slick-track .slick__slide .slide__content .view-mode-new_3 .news-content-wrapper-3 .content-wrapper .body-link .field-name-node-link a {
    text-decoration: none;
    color: #27499d;
  }
}

.page-news .view-vdrops-news-view {
  display: flex;
  flex-direction: column;
}

.page-news .view-vdrops-news-view .view-filters {
  padding: 0 16px;
  border-top: 1px solid #84dbff;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form {
  margin: 32px 0 16px 0;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-filters .views-exposed-form {
    margin: 48px 0;
  }
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets {
  display: flex;
  justify-content: center;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-filter-field_news_tax_target_id {
  padding: 0 10px 0 0;
  position: relative;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-filter-field_news_tax_target_id::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 20px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 7.5px 0 7.5px;
  border-color: #000000 transparent transparent transparent;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-filter-field_news_tax_target_id::after {
    top: 22px;
  }
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-filter-field_news_tax_target_id select {
  border: 0px;
  width: 140px;
  height: 28px;
  padding: 3px 0 3px 7px;
  font-size: 14px;
  background-color: #eeeeee;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-filter-field_news_tax_target_id select {
    padding: 7px 0 7px 14px;
    width: 220px;
    height: 50px;
    font-size: 18px;
  }
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-order {
  display: none;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-submit-button {
  display: none;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-by {
  padding: 0 0 0 10px;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-by .form-item-sort-by {
  position: relative;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-by .form-item-sort-by::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 7.5px 0 7.5px;
  border-color: #000000 transparent transparent transparent;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-by .form-item-sort-by::after {
    top: 22px;
    right: 20px;
  }
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-by .form-item-sort-by label {
  display: none;
}

.page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-by .form-item-sort-by select {
  border: 0px;
  width: 140px;
  height: 28px;
  padding: 3px 0 3px 7px;
  font-size: 14px;
  background-color: #eeeeee;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-filters .views-exposed-form .views-exposed-widgets .views-widget-sort-by .form-item-sort-by select {
    width: 220px;
    height: 50px;
    font-size: 18px;
    padding: 7px 0 7px 14px;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list {
    margin: -24px -50px;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li {
  padding: 16px 10px;
  margin: 0 -10px;
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li:hover {
  background-color: #eeeeee;
}

@media (min-width: 768px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li {
    max-width: 50%;
    flex-basis: 50%;
    margin: 0;
    padding: 16px 10px;
  }
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li:hover {
    background-color: unset;
  }
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li {
    max-width: 33.333%;
    flex-basis: 33.333%;
    padding: 24px 50px;
  }
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li:hover {
    background-color: unset;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper {
  display: flex;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper {
    flex-direction: column;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .image-cover .field-name-field-news-image {
  width: 100px;
}

@media (min-width: 480px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .image-cover .field-name-field-news-image {
    width: 160px;
  }
}

@media (min-width: 768px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .image-cover .field-name-field-news-image {
    width: 124px;
  }
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .image-cover .field-name-field-news-image {
    width: 100%;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .image-cover .field-name-field-news-image .field-items .field-item a img {
  width: 100%;
  display: block;
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .title {
  padding-left: 22px;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .title {
    padding-left: 20px;
  }
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .title {
    padding: 16px 0 12px 0;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .title h2 {
  margin: 0;
  line-height: 18px;
  font-weight: normal;
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .title h2 a {
  text-decoration: none;
  color: #27499d;
  font-size: 18px;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .title h2 a {
    font-size: 24px;
    line-height: 30px;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .date-taxonomy {
  padding-left: 22px;
  display: flex;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .date-taxonomy {
    padding-left: 0;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .date-taxonomy .field-name-post-date {
  font-size: 14px;
  color: #27499d;
}

@media (min-width: 1280px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .date-taxonomy .field-name-post-date {
    font-size: 16px;
  }
}

.page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .date-taxonomy .field-name-field-news-tax {
  font-size: 14px;
  color: #27499d;
  padding-left: 5px;
}

@media (min-width: 768px) {
  .page-news .view-vdrops-news-view .view-content .item-list ul.news_view_list li .view-mode-new_1 .news-content-wrapper .content-wrapper .date-taxonomy .field-name-field-news-tax {
    margin-right: -16px;
  }
}

#block-views-vdrops-news-view-block {
  background-color: #eeeeee;
  padding: 60px 50px 80px 50px;
}

@media (min-width: 480px) {
  #block-views-vdrops-news-view-block {
    padding: 60px 70px 80px 70px;
  }
}

@media (min-width: 768px) {
  #block-views-vdrops-news-view-block {
    padding: 60px 58px 80px 58px;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block {
    padding: 60px 90px 80px 90px;
  }
}

#block-views-vdrops-news-view-block h2.block__title {
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
  font-size: 26px;
  color: #27499d;
  text-align: center;
}

@media (min-width: 768px) {
  #block-views-vdrops-news-view-block h2.block__title {
    margin-bottom: 40px;
  }
}

#block-views-vdrops-news-view-block .view-content {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block .view-content {
    max-width: 1100px;
  }
}

@media (min-width: 1440px) {
  #block-views-vdrops-news-view-block .view-content {
    max-width: 1420px;
  }
}

#block-views-vdrops-news-view-block .view-content .item-list > ul {
  padding: 0;
  margin: -12px 0;
}

@media (min-width: 666px) {
  #block-views-vdrops-news-view-block .view-content .item-list > ul {
    display: flex;
    margin: 0 -10px;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block .view-content .item-list > ul {
    margin: 0px -50px;
  }
}

@media (min-width: 1440px) {
  #block-views-vdrops-news-view-block .view-content .item-list > ul {
    margin: 0px -70px;
  }
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li {
  list-style: none;
  padding: 15px 0;
}

@media (min-width: 666px) {
  #block-views-vdrops-news-view-block .view-content .item-list > ul > li {
    flex-basis: 33.33%;
    max-width: 33.33%;
    padding: 0 10px;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-news-view-block .view-content .item-list > ul > li {
    padding: 0px 50px;
  }
}

@media (min-width: 1440px) {
  #block-views-vdrops-news-view-block .view-content .item-list > ul > li {
    padding: 0px 70px;
  }
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .field-name-field-news-image {
  padding-bottom: 15px;
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .field-name-field-news-image a {
  display: block;
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .field-name-field-news-image a img {
  display: block;
  width: 100%;
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .content-warp .field-name-title {
  padding-bottom: 12px;
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .content-warp .field-name-title .field-item h2 {
  margin: 0;
  margin-top: 8px;
  line-height: 28px;
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .content-warp .field-name-title .field-item h2 a {
  text-decoration: none;
  color: #27499d;
  font-size: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .content-warp .tax-warp {
  display: flex;
  margin-top: 0px;
  font-size: 16px;
  color: #27499d;
}

#block-views-vdrops-news-view-block .view-content .item-list > ul > li .content-warp .tax-warp .field-name-field-news-tax {
  padding-left: 5px;
}

.node-type-contact .field-name-body .field-item .info-warp h2.title {
  margin-top: 32px;
}

@media (min-width: 768px) {
  .node-type-contact .field-name-body .field-item .info-warp h2.title {
    margin-top: 0px;
  }
}

.node-type-contact .field-name-body::after {
  margin: 31px 0 32px;
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 556px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) {
  .node-type-contact #webform-client-form-50 > div {
    max-width: 620px;
  }
}

.node-type-contact #webform-client-form-50 > div::before {
  text-align: center;
  margin-bottom: 32px;
}

.node-type-contact #webform-client-form-50 .form-item {
  margin: 0;
  margin-bottom: 32px;
}

@media (min-width: 480px) {
  .node-type-contact #webform-client-form-50 .form-item {
    margin-left: 90px;
    margin-right: 90px;
  }
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 .form-item {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.node-type-contact #webform-client-form-50 .webform-component--name, .node-type-contact #webform-client-form-50 .webform-component--tel {
  margin-top: 32px;
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 .form-actions {
    justify-content: flex-end;
    margin-top: -40px;
  }
  .node-type-contact #webform-client-form-50 .form-actions .clear {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 .webform-component--name, .node-type-contact #webform-client-form-50 .webform-component--email {
    padding-right: 10px;
  }
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 .webform-component--tel, .node-type-contact #webform-client-form-50 .webform-component--qa-select {
    padding-left: 10px;
  }
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 .webform-component--name, .node-type-contact #webform-client-form-50 .webform-component--tel, .node-type-contact #webform-client-form-50 .webform-component--email, .node-type-contact #webform-client-form-50 .webform-component--qa-select {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 .form-actions, .node-type-contact #webform-client-form-50 .webform-component--privacy {
    flex-basis: 50%;
  }
}

.node-type-contact #webform-client-form-50 .webform-component--header, .node-type-contact #webform-client-form-50 .webform-component--message {
  flex-basis: 100%;
  max-width: 100%;
}

.node-type-contact #webform-client-form-50 .webform-component-fieldset {
  font-size: 18px;
  line-height: 1.67;
  color: #323237;
  text-align: center;
}

@media (min-width: 768px) {
  .node-type-contact #webform-client-form-50 .webform-component-fieldset {
    text-align: start;
  }
}

.node-type-contact #webform-client-form-50 .form-item-submitted-privacy-1 {
  display: flex;
}

@media (min-width: 480px) {
  .node-type-contact #webform-client-form-50 .form-item-submitted-privacy-1 {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.node-type-contact #webform-client-form-50 .form-item-submitted-privacy-1 #edit-submitted-privacy-1 {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #7d7d7d;
  background: #ffffff;
  align-self: center;
  margin-right: 9px;
}

.node-type-contact #webform-client-form-50 .form-item-submitted-privacy-1 .option {
  font-size: 16px;
}

#colorbox #cboxContent #cboxPrevious,
#colorbox #cboxContent #cboxNext,
#colorbox #cboxContent #cboxClose {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  outline: none;
  border: none;
  transition: all .3s;
}

#colorbox #cboxContent #cboxPrevious,
#colorbox #cboxContent #cboxNext {
  width: 26px;
  height: 48px;
}

#colorbox #cboxContent #cboxPrevious:hover,
#colorbox #cboxContent #cboxNext:hover {
  width: 26px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  outline: none;
  border: none;
}

#colorbox #cboxContent #cboxPrevious {
  top: 50%;
  left: -120px;
  background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-back.png);
}

#colorbox #cboxContent #cboxPrevious:hover {
  background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-back-active.png);
}

#colorbox #cboxContent #cboxNext {
  top: 50%;
  right: -120px;
  background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-next.png);
}

#colorbox #cboxContent #cboxNext:hover {
  background-image: url(/sites/all/themes/vdrops/images/icon/colorbox-next-active.png);
}

#colorbox #cboxContent #cboxClose {
  top: 0;
  right: -50px;
  background-image: url(/sites/all/themes/vdrops/images/icon/close@3x.png);
  width: 36px;
  height: 36px;
}

#block-views-vdrops-product-view-block-3 {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

@media (min-width: 1280px) {
  #block-views-vdrops-product-view-block-3 {
    padding: 0 10px;
  }
}

#block-views-vdrops-product-view-block-3 .view-content {
  border-bottom: 1px solid #84dbff;
}

#block-views-vdrops-product-view-block-3 .view-content .views-row-first {
  max-width: 1120px;
  width: 100%;
  list-style: none;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block {
  display: flex;
  flex-wrap: wrap;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block > div {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 555px) {
  #block-views-vdrops-product-view-block-3 .view-content .product-slide-block > div {
    flex-basis: 50%;
    max-width: 50%;
  }
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title {
  margin-bottom: 8px;
}

@media (min-width: 555px) {
  #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title {
    margin-bottom: 18px;
  }
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title h2 {
  margin: 0;
  font-size: 30px;
  line-height: 40px;
  color: #27499d;
  text-align: left;
}

@media (min-width: 555px) {
  #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title h2 {
    font-size: 36px;
    line-height: 42px;
  }
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-feature {
  font-size: 18px;
  color: #323237;
  margin-bottom: 18px;
}

@media (min-width: 555px) {
  #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-feature {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-narrative,
#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price {
  text-align: left;
  font-size: 18px;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-narrative {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-narrative::before {
  content: "入數：";
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price::before {
  content: "售價：";
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price .field-label {
  display: none;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-shopping {
  margin-bottom: 32px;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-shopping a {
  text-decoration: none;
  padding: 12px 34px;
  background-color: #84dbff;
  font-size: 18px;
  color: #fff;
  border-radius: 18px;
  display: block;
  width: 140px;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__slider {
  padding: 0;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow > button {
  opacity: 0.6;
  top: 50%;
}

@media (min-width: 555px) {
  #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow > button {
    width: 24px;
    height: 48px;
    background-size: 24px 48px;
  }
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow > button:hover {
  opacity: 1;
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow .slick-prev {
  left: 0;
  background-image: url(../images/icon/previous.png);
}

#block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow .slick-next {
  right: 0;
  background-image: url(../images/icon/next.png);
}

.i18n-en #block-views-vdrops-product-view-block-3 {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}

@media (min-width: 1280px) {
  .i18n-en #block-views-vdrops-product-view-block-3 {
    padding: 0 10px;
  }
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content {
  border-bottom: 1px solid #84dbff;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .views-row-first {
  max-width: 1120px;
  width: 100%;
  list-style: none;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block {
  display: flex;
  flex-wrap: wrap;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block > div {
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 555px) {
  .i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block > div {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title {
  margin-bottom: 8px;
}

@media (min-width: 555px) {
  .i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title {
    margin-bottom: 18px;
  }
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title h2 {
  margin: 0;
  font-size: 30px;
  line-height: 40px;
  color: #27499d;
  text-align: left;
}

@media (min-width: 555px) {
  .i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-title h2 {
    font-size: 36px;
    line-height: 42px;
  }
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-feature {
  font-size: 18px;
  color: #323237;
  margin-bottom: 18px;
}

@media (min-width: 555px) {
  .i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-feature {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-narrative,
.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price {
  text-align: left;
  font-size: 18px;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-narrative {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-narrative::before {
  content: "Quantity：";
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price::before {
  content: "Price：";
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-price .field-label {
  display: none;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-shopping {
  margin-bottom: 32px;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .info .field-name-field-product-shopping a {
  text-decoration: none;
  padding: 12px 34px;
  background-color: #84dbff;
  font-size: 18px;
  color: #fff;
  border-radius: 18px;
  display: block;
  width: 140px;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__slider {
  padding: 0;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow > button {
  opacity: 0.6;
  top: 50%;
}

@media (min-width: 555px) {
  .i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow > button {
    width: 24px;
    height: 48px;
    background-size: 24px 48px;
  }
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow > button:hover {
  opacity: 1;
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow .slick-prev {
  left: 0;
  background-image: url(../images/icon/previous.png);
}

.i18n-en #block-views-vdrops-product-view-block-3 .view-content .product-slide-block .slide-wrapper .slick__arrow .slick-next {
  right: 0;
  background-image: url(../images/icon/next.png);
}

#block-views-vdrops-product-view-block-2 h2.block__title {
  font-size: 24px;
  font-weight: bold;
  color: #27499d;
  display: flex;
  margin: 32px 0 16px 0;
}

#block-views-vdrops-product-view-block-2 h2.block__title:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-top: 8px;
  margin-right: 8px;
  border-style: solid;
  border-width: 9px 0 9px 10px;
  border-color: transparent transparent transparent #84dbff;
}

#block-views-vdrops-product-view-block-2 .view-content > div > ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

#block-views-vdrops-product-view-block-2 .view-content > div > ul li {
  list-style: none;
  flex-basis: 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  #block-views-vdrops-product-view-block-2 .view-content > div > ul li {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-product-view-block-2 .view-content > div > ul li {
    flex-basis: 100%;
    max-width: 100%;
  }
}

#block-views-vdrops-product-view-block-2 .product-rm {
  display: flex;
  margin: 0 -10px;
}

#block-views-vdrops-product-view-block-2 .product-rm > div {
  padding: 0 10px;
}

#block-views-vdrops-product-view-block-2 .product-rm .field-name-field-product-image {
  flex-basis: 120px;
  max-width: 120px;
}

@media (min-width: 480px) {
  #block-views-vdrops-product-view-block-2 .product-rm .field-name-field-product-image {
    flex-basis: 180px;
    max-width: 180px;
  }
}

@media (min-width: 768px) {
  #block-views-vdrops-product-view-block-2 .product-rm .field-name-field-product-image {
    flex-basis: 31%;
    max-width: 31%;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-product-view-block-2 .product-rm .field-name-field-product-image {
    flex-basis: 43%;
    max-width: 43%;
  }
}

#block-views-vdrops-product-view-block-2 .product-rm .content-warp .field-name-title {
  margin-bottom: 5px;
}

#block-views-vdrops-product-view-block-2 .product-rm .content-warp .field-name-title h2 {
  margin: 0;
}

#block-views-vdrops-product-view-block-2 .product-rm .content-warp .field-name-title h2 a {
  font-size: 18px;
  color: #27499d;
  line-height: 22px;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

#block-views-vdrops-product-view-block-2 .product-rm .content-warp .field-name-field-product-narrative,
#block-views-vdrops-product-view-block-2 .product-rm .content-warp .field-name-field-product-price {
  text-align: left;
  font-size: 14px;
}

.field-name-addtoany {
  margin-bottom: 10px !important;
}

@media (min-width: 555px) {
  .field-name-addtoany {
    margin-bottom: 25px !important;
  }
}

.field-name-addtoany a > .a2a_svg {
  width: 24px !important;
  height: 24px !important;
  border-radius: 0px !important;
}

.field-name-addtoany .a2a_button_email > .a2a_svg {
  background-color: #F5952E !important;
}

#block-views-vdrops-benefit-block-1 h2.block__title,
#block-views-vdrops-what-is-block-1 h2.block__title,
#block-views-vdrops-our-water-block-1 h2.block__title {
  font-size: 24px;
  font-weight: bold;
  color: #27499d;
  display: flex;
  margin: 32px 0 16px 0;
}

#block-views-vdrops-benefit-block-1 h2.block__title:before,
#block-views-vdrops-what-is-block-1 h2.block__title:before,
#block-views-vdrops-our-water-block-1 h2.block__title:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-top: 8px;
  margin-right: 8px;
  border-style: solid;
  border-width: 9px 0 9px 10px;
  border-color: transparent transparent transparent #84dbff;
}

#block-views-vdrops-benefit-block-1 .view-content ul,
#block-views-vdrops-what-is-block-1 .view-content ul,
#block-views-vdrops-our-water-block-1 .view-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  #block-views-vdrops-benefit-block-1 .view-content ul,
  #block-views-vdrops-what-is-block-1 .view-content ul,
  #block-views-vdrops-our-water-block-1 .view-content ul {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-benefit-block-1 .view-content ul,
  #block-views-vdrops-what-is-block-1 .view-content ul,
  #block-views-vdrops-our-water-block-1 .view-content ul {
    flex-direction: column;
  }
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row {
  padding: 16px 10px;
  margin: 0 -10px;
  width: 100%;
}

@media (min-width: 768px) {
  #block-views-vdrops-benefit-block-1 .view-content ul li.views-row,
  #block-views-vdrops-what-is-block-1 .view-content ul li.views-row,
  #block-views-vdrops-our-water-block-1 .view-content ul li.views-row {
    flex-basis: 50%;
    max-width: 50%;
    padding: 16px 32px 16px 0;
    margin: 0;
  }
}

@media (min-width: 1280px) {
  #block-views-vdrops-benefit-block-1 .view-content ul li.views-row,
  #block-views-vdrops-what-is-block-1 .view-content ul li.views-row,
  #block-views-vdrops-our-water-block-1 .view-content ul li.views-row {
    flex-basis: 100%;
    max-width: 100%;
  }
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .contextual-links,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .contextual-links,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .contextual-links {
  display: none;
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node {
  display: flex;
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node .field-type-image,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node .field-type-image,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node .field-type-image {
  max-width: 40%;
  padding: 0;
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node .field-type-image a,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node .field-type-image a,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node .field-type-image a {
  height: auto !important;
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node .field-type-image a img,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node .field-type-image a img,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node .field-type-image a img {
  display: block;
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node .field-name-title,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node .field-name-title,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node .field-name-title {
  padding-left: 20px;
}

@media (min-width: 1280px) {
  #block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node .field-name-title,
  #block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node .field-name-title,
  #block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node .field-name-title {
    padding-left: 13px;
  }
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node .field-name-title h2,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node .field-name-title h2,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node .field-name-title h2 {
  margin: 0;
  line-height: 1.22;
  font-size: 18px;
  font-weight: bold;
}

#block-views-vdrops-benefit-block-1 .view-content ul li.views-row .node .field-name-title a,
#block-views-vdrops-what-is-block-1 .view-content ul li.views-row .node .field-name-title a,
#block-views-vdrops-our-water-block-1 .view-content ul li.views-row .node .field-name-title a {
  color: #27499d;
  text-decoration: none;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
