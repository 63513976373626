/**商品列表上方區塊樣式**/

.content-top-region {
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #84dbff;
    ;
  }

  #block-bean-shi-he-mei-ge-ren-de-jian-kang-h {
    h2.block__title {
      font-size: 36px;
      text-align: center;
      color: #27499d;
      line-height: 1.33;
      margin-top: 0;
      margin-bottom: 32px;
      @include zen-respond-to(laptop) {
        font-size: 48px;
      }
    }

    .field-name-field-product-block-image {
      display: none;

      @include zen-respond-to(pad) {
        display: block;
      }

      .field-items {
        display: flex;
        justify-content: space-between;
      }
    }

    .field-name-field-product-body {
      .field-item {
        font-size: 18px;
        line-height: 48px;
        text-align: center;
        color: #323237;
        margin-bottom: 44px;
        line-height: 36px;
        @include zen-respond-to(laptop) {
          font-size: 24px;
          line-height: 48px;
        }
      }
    }

    .field-name-field-product-block-image {
      .field-item:not(:nth-child(3)) {
        font-size: 18px;
        line-height: 2;
        text-align: center;
        color: #323237;
        margin-bottom: 53px;
        padding-right: 20px;

        @include zen-respond-to(laptop) {
          padding-right: 100px;
        }
      }
    }
  }
}


/**商品列表樣式**/

.page-product {
  .layout-3col__full {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      display: none;
    }
  }

  .view-vdrops-product-view {
    >h2 {
      margin: 64px 0px;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 60px;
      border: 2px solid #84dbff;
      border-radius: 15px;
      background-color: #ffffff;
      font-size: 26px;
      text-decoration: none;
      text-align: center;
      color: #27499d;
      max-width: 228px;

      @include zen-respond-to(mobile) {
        padding: 5px 120px;
        padding-bottom: 2px;
        max-width: 348px;
      }

      @include zen-respond-to(pad) {
        padding: 5px 99px;
        padding-bottom: 2px;
        max-width: 310px;
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: 17px;
    height: 37px;
    border-radius: 0;
    background-size: 17px 37px;
  }
}

.page-main .layout-3col__full>#block-views-8f5469fed0e9ea761ea3ee66a3edc0aa,
#block-views-vdrops-product-view-block-1 {
  h2.block__title {
    // margin-left: auto;
    // margin-right: auto;
    // margin-top: 80px;
    // margin-bottom: 40px;
    margin: 80px 50px 40px 50px;
    // padding: 13px 20px;
    padding: 13px 0;
    border: 2px solid #84dbff;
    border-radius: 15px;
    background-color: #ffffff;
    text-decoration: none;
    text-align: center;
    color: #27499d;
    line-height: normal;

    @include zen-respond-to(mobile) {
      // padding: 8px 0px;
      max-width: 348px;
      width: 100%;
margin-left: auto;
margin-right: auto;
    }

    @include zen-respond-to(pad) {
      padding: 7px 31px;
      max-width: 310px;
      margin-bottom: 64px;
    }
  }
}

#block-bean-xiao-fen-zi-tuan-shui-san-da-te- {
  h2.block__title {
    margin-top: 19px;
    margin-bottom: 64px;
  }
}

.view-vdrops-product-view>.view-content>.item-list {
  >ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-top: 48px;
  }
}

.view-vdrops-product-view>.view-content>.item-list>.product-list>.views-row {
  list-style: none;
  flex-basis: 50%;
  padding: 0 20px;

  // margin-bottom: 36px;
  @include zen-respond-to(pad) {
    flex-basis: 33.3333%;
  }

  @include zen-respond-to(laptop) {
    flex-basis: 24.9999%;
  }
}

.node-product,
.view-vdrops-product-view>.view-content>.item-list>ul>.views-row {
  .field-name-field-product-image {
    display: flex;
    justify-content: center;
  }

  .field-name-title {
    h2 {
      text-align: center;
      font-size: 24px;
      line-height: 1.11;
      color: #27499d;
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }

  .field-name-field-product-narrative {
    text-align: center;
    // font-style: 16px;
    font-size: 18px;
    color: #27499d;
    margin-bottom: 5px;
  }

  .field-name-field-product-price {
    text-align: center;
    // font-style: 16px;
    font-size: 18px;
    color: #27499d;
  }

  .field-name-node-link {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 48px;

    .field-items {
      .field-item {
        a {
          padding: 9px 21px;
          border: 2px solid #84dbff;
          border-radius: 10px;
          background-color: #ffffff;
          text-decoration: none;
          color: #7ecdeb;

          @include zen-respond-to(laptop) {
            border-radius: 15px;
            padding: 6px 29px;
          }

          &:hover {
            background-color: #84dbff;
            color: #ffffff;
          }
        }
      }
    }
  }
}

#block-views-vdrops-product-view-block-1 {
  max-width: 1243px;
  margin: 0 auto;
}

.view-vdrops-product-view>.view-content {
  .slick__slider {
    width: 100%;
    padding: 0 60px;
    margin: 0 auto;
  }
}

.slick-views-vdrops-product-view-2-slider {
  .slick-list {
    width: 100%;
    padding: 0;
  }

  // .slide__content{
  //   max-width: 240px;
  //   margin-bottom: 56px;
  // }
}

#slick-views-vdrops-product-view-2 {
  .slick__slide {
    outline: 0;
    border: 0;
    padding: 0 15px;
  }
}

/**SlideShow Arrow樣式**/

.slick__arrow {
  .slick-prev {
    background-image: url("../images/icon/arrow-left.png");
    left: 10px;
    top: -120px;

    &::before {
      color: unset;
      content: unset;
    }
  }

  .slick-next {
    background-image: url("../images/icon/arrow-right.png");
    right: 10px;
    top: -120px;

    &::before {
      color: unset;
      content: unset;
    }
  }

  .slick-prev,
  .slick-next {
    width: 17px;
    height: 37px;
    border-radius: 0;
    background-size: 17px 37px;
  }
}

.node-product {
  .field-name-node-link {
    // margin-bottom: 125px;
    margin-bottom: 91px;
    @include zen-respond-to(pad) {
   margin-bottom: 131px;
    }
  }
}

/**PAGER樣式**/

.pager {
  margin: 72px -25px;

  >li {
    padding: 0 18px;

    >a {
      text-decoration: none;
      font-size: 20px;
      color: #27499d;
      padding: 15px 21px;

      &:hover {
        background-color: #eeeeee;
        border-radius: 999em;
      }
    }
  }

  .pager-current {
    font-size: 20px;
    color: #323237;
    font-weight: normal;
  }

  .pager-first {
    a {
      width: 11px;
      color: transparent;
      background: url("../images/icon/page-first.png") no-repeat center;

      &:hover {
        color: transparent;
        background: url("../images/icon/page-first-hover.png") no-repeat center;
      }
    }
  }

  .pager-previous {
    a {
      width: 8px;
      color: transparent;
      background: url("../images/icon/page-back.png") no-repeat center;

      &:hover {
        color: transparent;
        background: url("../images/icon/page-back-hover.png") no-repeat center;
      }
    }
  }

  .pager-next {
    a {
      width: 8px;
      color: transparent;
      background: url("../images/icon/page-next.png") no-repeat center;

      &:hover {
        color: transparent;
        background: url("../images/icon/page-next-hover.png") no-repeat center;
      }
    }
  }

  .pager-last {
    a {
      width: 11px;
      color: transparent;
      background: url("../images/icon/page-last.png") no-repeat center;

      &:hover {
        color: transparent;
        background: url("../images/icon/page-last-hover.png") no-repeat center;
      }
    }
  }
}
